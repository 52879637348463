.table {
    width: 100%;
    background-color: transparent;
    border-spacing: 0;
    border: 0;
    max-width: 100%;
    margin-bottom: $gutter-width * 2;
    color: #444;
    td {
        padding: 10px 6px;
    }
    th {
        text-align: left;
        padding: 2px 6px 8px;
    }
    th {
        font-weight: bold;
        border-bottom: 1px solid #c8c8c8;
        @include font-size(1);
    }
    td {
        border-bottom: 1px solid #ddd;
        vertical-align: middle;
        height: 40px;
        @include font-size(1.3);
    }
    &.table--striped {
        tbody tr:nth-child(odd) td {
            background: #fdfdfd;
            -webkit-print-color-adjust: exact;
        }
        tfoot td {
            background: #fff;
            border-bottom: none;
            font-style: italic;
        }
    }
    &.table--vertical {
        th {
            vertical-align: middle;
        }
    }
    &.is-clickable {
        tbody tr:hover td {
            background: lighten($grey, 58);
        }
        td {
            cursor: pointer;
        }
    }
}
/** Layout
---------------------------------------- */
$gutter-width: 15px;
$susy: (
        columns: 12,
        gutters: .25,
        gutter-position: inside
);

/** Farben
---------------------------------------- */
$black: #333;
$grey: #666;
$light-grey: #EFEFF1;
$white: #fcfcfc;

$green: #48b059;
$orange: #faad28;
$red: #ea6048;
$blue: #2db8ba;
$purple: #d7649f;

$colorList: "green" $green, "orange" $orange, "red" $red, "blue" $blue, "purple" $purple, "grey" $grey;

$main-background-color: #fafafa;
$toolbar-background-color: #EFEFF1;
$nav-module-background-color: rgba(0, 0, 0, .8);

// Buttons
$btn-default: #fff;
$btn-primary: #f52f33;
$btn-secondary: #3278f5;

%betterScrollbars {
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        box-shadow: none;
        border: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #CECECE;
    }
    &::-webkit-scrollbar-track {
        background-color: #EAEAEA;
        border-left: 1px solid #CECECE;
    }
}
%betterScrollbarsSmall {
    &::-webkit-scrollbar {
        width: 3px;
    }
}

.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .3);
    opacity: 0;
    visibility: hidden;
    z-index: 7500;
    transition: opacity .3s 0, visibility .3s 0;

    &--absolute {
        position: absolute;
    }

    &__container {
        transform: translateY(-40px);
        border-radius: 5px;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        margin: 12em auto 0;
        background: #fff;
        position: relative;
        z-index: 8000;
        width: 400px;
        min-width: 400px;
        max-width: 90%;

        transition: transform .3s;
    }
    &__header {
        padding: $gutter-width * 2;
        background: #eaeaea;
        position: relative;
        border-radius: 5px 5px 0 0;
        .js-close-modal {
            font-weight: bold;
            position: absolute;
            right: 10px;
            cursor: pointer;
            color: #888;
            top: 15px;
            width: 25px;
            height: 25px;
            text-align: center;
            &:hover {
                color: #666;
            }
        }
    }
    &__body {
        padding: 15px 10px;
        overflow-y: auto;
        h3:first-child {
            margin-top: -14px;
            margin-bottom: 20px;
        }
        hr {
            margin: 20px 0;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: none;
            border: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
        }
        &::-webkit-scrollbar-track {
            background-color: #eee;
            border-left: 1px solid #CECECE;
        }
    }
    p {
        margin-bottom: 12px;
        line-height: 1.5;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.is-visible {
        opacity: 1;
        visibility: visible;
        transition: opacity .3s 0, visibility 0 0;

        .modal__container {
            transform: translateY(0px);
        }
    }
    &__buttons {
        font-size: 0;
        text-align: justify;
        a {
            @include font-size(1.6);
            display: block;
            height: 60px;
            position: relative;
            line-height: 60px;
            text-transform: uppercase;
            color: $black !important;
            background: $btn-default;
            transition: background-color .2s;
            &.is-loading {
                transition: color linear .2s;
                color: rgba(255, 255, 255, .0) !important;
                cursor: default;
            }
            &:hover {
                background: darken($btn-default, 5);
            }
        }
        li {
            display: inline-block;
            text-align: center;
            width: 50%;
            &:first-child a {
                border-radius: 0 0 0 5px;
            }
            &:last-child a {
                border-radius: 0 0 5px 0;
            }
            .primary {
                background: $btn-primary;
                color: #fff !important;
                &:hover {
                    background: lighten($btn-primary, 5) !important;
                }
            }
            .secondary {
                background: $btn-secondary;
                color: #fff !important;
                &:hover {
                    background: lighten($btn-secondary, 5) !important;
                }
            }
        }
        &--single li {
            width: 100%;
            a {
                border-radius: 0 0 5px 5px !important;
            }
        }
        &--triple li {
            width: 33.3333%;
        }
    }
}

.modal-confirm {
    .modal__container {
        width: 500px;
    }
    &__body {
        text-align: center;
        p {
            padding: 2em 1em;
            margin: 0;
            @include font-size(1.5);
        }
    }
    &__buttons li:first-child a {
        background: $red;
        color: #fff;
        &:hover {
            background: lighten($red, 5);
        }
    }
}

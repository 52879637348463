/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 5, 2014 */
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/opensans-light-webfont.eot');
    src: url('../../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-light-webfont.woff') format('woff'),
    url('../../fonts/opensans-light-webfont.ttf') format('truetype'),
    url('../../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/opensans-bold-webfont.eot');
    src: url('../../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-bold-webfont.woff') format('woff'),
    url('../../fonts/opensans-bold-webfont.ttf') format('truetype'),
    url('../../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/opensans-semibold-webfont.eot');
    src: url('../../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-semibold-webfont.woff') format('woff'),
    url('../../fonts/opensans-semibold-webfont.ttf') format('truetype'),
    url('../../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/opensans-regular-webfont.eot');
    src: url('../../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-regular-webfont.woff') format('woff'),
    url('../../fonts/opensans-regular-webfont.ttf') format('truetype'),
    url('../../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
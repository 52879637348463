.filebrowser-overlay.is-active {

    .filebrowser__body {
        position: absolute;
        left: 50%;
        width: 80vw;
        height: 99vh;
        height: calc(100vh - 52px);
        background: #FFF;
        padding: 1em;
        box-sizing: border-box;
        font-size: 1.4rem;
        border-radius: 2px;
        box-shadow: 0 0 31px rgba(0, 0, 0, 0.2);
        overflow-y: auto;
        overflow-x: hidden;
        will-change: transform;
        transform: translate(-50%, 0%);

        @extend %betterScrollbars;
    }

    .file-list__entry--empty {
        width: calc(100% - 2.1em) !important;
    }
}

.breadcrumbs {
    width: 100%;
    margin-bottom: $gutter-width;
    padding: .5em 0;
    @include font-size(1.2);
    > li {
        display: inline;
        margin-right: 10px;
    }
    a {
        padding: .25em .5em;
        display: inline-block;
        background: #f0f0f0;
        border-radius: 2px;
        max-width: 15em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.media-toolbar {
    float: left;
    margin-right: 2em;
}

.file-list {
    margin-top: 30px;
    &--loading {
        opacity: .6;
    }
    &__loading-indicator {
        text-align: center;
        margin-top: 100px;
    }
    .placeholder {
        height: 0 !important;
        opacity: 0;
        visibility: hidden;
    }
    a {
        color: #555;
        &:hover {
            color: #333;
        }
    }

    &__entry--empty {
        text-align: center;
        padding: 45px 0 40px;
        width: 100% !important;
        height: auto !important;
        border: 1px solid #EDF0F1;
        background: #F6F7F9;
        display: inline-block;
        margin: 1em;
        border-radius: 4px;
        .btn {
            margin-left: 20px;
        }
    }
}

.file-list--list {
    .file-list__entry {
        border-bottom: 1px solid #EAEAEA;
        padding: 11px 0;
        display: block;
        height: 46px;
        & > div {
            display: inline-block;
            vertical-align: middle;
            position: relative;
        }
    }
    .file__checkbox {
        top: 4px;
        left: 3px;
        position: relative;
    }
    .file__icon {
        margin: 2px 15px 0;
        img {
            width: 16px;
        }
    }
    .file__size {
        margin-top: 5px;
        float: right;
        @include font-size(1.2);
    }
    .file__thumbnail {
        display: none !important;
    }
}

.file-list--grid {
    text-align: justify;
    margin: 30px -1em;
    .file-list__entry {
        border: 1px solid #EDF0F1;
        background: #F6F7F9;
        display: inline-block;
        margin: 1em;
        width: 30%;
        height: 100px;
        position: relative;
        border-radius: 4px;

        & > div {
            position: absolute;
        }
    }
    .file__checkbox {
        right: -8px;
        top: 5px;
        z-index: 2000;
    }
    .file__icon {
        display: none;
    }
    .file__thumbnail {
        left: 0;
        top: 0;
        width: 100px;
        height: 98px;
        border-right: 1px solid #EDF0F1;
        cursor: pointer;
        &-container {
            overflow: hidden;
            position: relative;
            height: 100%;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
    .file__name {
        left: 120px;
        top: 28px;
        width: 50%;
        width: calc(100% - 140px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.5;
    }
    .file__size {
        position: absolute;
        left: 120px;
        top: 57px;
        @include font-size(1.1);
        color: #aaa;
    }
    .file--dir .file__thumbnail {
        cursor: pointer;
        &:hover {
            background: lighten(#F6F7F9, 1);
        }
    }
}

.file-list--grid.file-list--thumbs-only {
    .file-list__entry {
        width: 150px;
        height: 150px;
    }
    .file__thumbnail {
        width: 150px;
        height: 148px;
    }
    .file__checkbox {
        right: 8px;
    }
}

.file-list--flex {
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    .file-list__entry {
        width: 20% !important;
        height: 150px !important;
        flex: 1 1 auto;

        &.placeholder {
            height: 0 !important;
        }
    }
    .file__thumbnail {
        width: 100% !important;
        height: 100% !important;
    }
}

.file__loader {
    position: absolute;
    z-index: 2000;
    width: 100%;
    height: 100%;
}

.file-list__entry.not-deployed {
    .file__loader + img {
        opacity: .4;
    }
}

.upload-queue {
    width: 500px;
    min-height: 10vh;
    max-height: 40vh;
    overflow: hidden;
    position: fixed;
    right: 40px;
    bottom: -40vh;
    background: #fefefe;
    border-top: 1px solid #CFCFCF;
    border-left: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    transition: bottom .3s ease, opacity .3s ease;

    &.is-visible {
        bottom: -1px;
    }

    &__header {
        background: #EDEDED;
        color: #333;
        padding: .75em .5em;
        font-weight: 500;
        .header-action-hide {
            float: right;
            cursor: pointer;
        }
    }

    &__list {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 30vh;
    }

    &__entry {
        background: #f8f8f8;
        border-top: 1px solid #eee;
        padding: 0.75em 0.5em;
        position: relative;
        height: 2.6em;
        @include font-size(1.3);

        &.dz-success {
            .action-cancel {
                display: none;
            }
            .status-okay {
                display: block;
            }
            .filename {
                color: #4B7728;
            }
            .progressbar {
                background: #B4E4A1;
                border-right: 0;
            }
        }
        &.dz-error {
            .action-cancel {
                color: $red;
            }
            .filename {
                color: $red;
            }
            .progressbar {
                background: #F2DEDE;
                border-right: 0;
            }
        }
    }

    .thumbnail {
        width: 20px;
        position: relative;
        z-index: 1000;
        margin-right: 8px;
        background: #F0F0F0;
        height: 20px;
        display: inline-block;
        margin-top: -3px;
    }

    .filename {
        display: inline-block;
        position: relative;
        z-index: 1000;
        overflow: hidden;
        width: 80%;
        padding-right: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 3px;
        color: #555;
    }

    .action-cancel {
        float: right;
        position: relative;
        z-index: 1000;
        &:hover {
            background: #DDD;
            border-radius: 50%;
            line-height: 1;
            text-align: center;
            cursor: pointer;
        }
    }
    .status-okay {
        float: right;
        position: relative;
        z-index: 1000;
        display: none;
        color: #4B7728;
        cursor: pointer;
    }

    .progressbar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #fff;
        border-right: 2px solid #bbb;
        z-index: 0;
        transition: width .5s ease;
    }
}
.color {
    @each $color in $colorList {
        &-#{nth($color,1)} {
            .header {
                border-bottom-color: nth($color, 2);
            }
            .nav-sidebar .active a {
                background-color: nth($color, 2);
            }
            a {
                color: nth($color, 2);
            }
            a:active {
                color: lighten(nth($color, 2), 10);
            }
            a:hover {
                color: lighten(nth($color, 2), 5);
            }
            .pagination .active span {
                background-color: nth($color, 2);
                border-color: nth($color, 2);
            }
            input[type="checkbox"]:checked + label span {
                background-color: nth($color, 2);
                border-color: nth($color, 2);
            }
            .btn-primary {
                background: nth($color, 2) !important;
                color: #fff !important;
                border-color: nth($color, 2);
                &:hover {
                    background: lighten(nth($color, 2), 5) !important;
                }
                &[disabled],
                &.disabled {
                    background: lighten(nth($color, 2), 15);
                    border-color: lighten(nth($color, 2), 15);
                    color: rgba(255, 255, 255, .7) !important;
                }
            }

            .btn-group .btn-primary:not(:first-child) {
                border-left-color: darken(nth($color, 2), 5);
            }
            .dropdown ul li {
                &:hover {
                    background: nth($color, 2);
                }
                &.is-selected {
                    color: nth($color, 2);
                    a {
                        color: nth($color, 2);
                        &:hover {
                            color: #fff;
                        }
                    }
                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }
    &-grey .header {
        border-bottom-color: #fff;
    }
}
.color {
    @each $color in $colorList {
        &-#{nth($color,1)} {
            .sweet-alert button {
                box-shadow: none !important;
                &.confirm {
                    background: nth($color, 2) !important;
                    color: #fff !important;
                }
            }
        }
    }
}

.sweet-alert {
    h2 {
        font-size: 25px !important;
    }
    p {
        font-size: 18px !important;
        line-height: 1.7 !important;
    }
}
.sweet-alert button {
    &.cancel {
        background: #fafafa;
        border: 1px solid #e0e0e0;
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="datetime"],
input[type="time"],
input[type="range"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="file"],
textarea,
select {
    display: block;
    border-radius: 4px;
    font: 13px 'Open Sans', Arial, sans-serif;
    border: 2px solid #e0e0e0;
    padding: 7px 5px;
    outline: none;
    color: #777;
    margin: 0;
    width: 310px;
    max-width: 100%;
    background: #fff;
    &:focus {
        color: #333;
        border-color: #aaa;
        box-shadow: 0 0 5px rgba( 0, 0, 0, .1);
    }
    &.xxx-small {
        @include column_width(1);
        text-align: center;
    }
    &.xx-small {
        @include column_width(2);
    }
    &.x-small {
        @include column_width(3);
    }
    &.small {
        @include column_width(4);
    }
    &.xxx-large {
        @include column_width(8);
    }
    &.xx-large {
        @include column_width(7);
    }
    &.x-large {
        @include column_width(6);
    }
    &.large {
        @include column_width(5);
    }

    &.activation-code {
        font-size: 38px;
        font-family: "Courier New", monospace;
        padding: 5px 10px 3px;
    }
}

input[type="range"] {
    &:focus {
        box-shadow: none;
    }
}

textarea {
    min-height: 60px;
}

.table input, .table select {
    margin-bottom: 0;
}

label,
legend {
    @include font-size(1.4);
    display: block;
    color: #555;
    font-weight: bold;
    margin-bottom: 6px;
    cursor: pointer;
    white-space: nowrap;
    span {
        font-weight: normal;
        @include font-size(1.3);
        padding-left: 10px;
        color: #AAA;
        white-space: nowrap;
    }
    &.not-bold {
        font-weight: normal;
    }
}

.form-control {
    margin-bottom: 20px;
    &-inline {
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        }
}

.error {
    label, legend {
        color: #e74c3c !important;
        span {
            background: #e74c3c;
            color: #fff;
            padding: 0 3px 0 3px;
            margin-left: 15px;
            font-size: 90%;
            border-radius: 3px;
            white-space: nowrap;;
            font-weight: bold;
        }
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="datetime"],
    input[type="time"],
    input[type="range"],
    input[type="file"],
    input[type="tel"],
    input[type="search"],
    textarea,
    select {
        color: #e74c3c !important;
        border-color: #e74c3c !important;
        &:focus {
            box-shadow: 0 0 5px rgba( 255, 0, 0, .35);
        }
    }
}



.fakebox {
    border: 2px solid #e0e0e0;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    display: inline-block;
    transition: .2s ease border-color;
    cursor: pointer;
    &:hover {
        border-color: #bbb;
    }
}

input[type="checkbox"] {
    display: none;
    user-select: none;
}

input[type="checkbox"] {
    + label span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: -2px 12px 0 0;
        vertical-align: middle;
        transition: .2s ease border-color, .2s ease background-color;
        border: 2px solid #e0e0e0;
        border-radius: 2px;
        background: none center center no-repeat #fff;
        cursor: pointer;
        user-select: none;
        &:hover {
            border-color: #bbb;
        }
    }
    &:checked + label span {
        background-image: url("../../images/check.png");
        font-weight: normal;
    }
    + label {
        font-weight: normal;
    }
    &.checkbox--small + label span {
        width: 17px;
        height: 17px;
        border-width: 1px;
    }
}
@page {
    size: A4;
    margin: 1cm;
}

@page :first {
    margin: 0cm 1cm 1cm;
}

@media print {
    .sidebar-left,
    .toolbar,
    .hide-on-print {
        display: none !important;
    }
    .page-wrapper {
        background: none !important;
    }
    .page-title {
        margin: 40px 0;
        img {
            display: none;
        }
        h2 {
            margin-left: 0;
        }
        border-bottom: 1px solid #888;
    }
    .main {
        display: block;
        margin: 0;
        width: 100%;
        min-height: 26.7cm;
        border-radius: 0;
    }
    .content, .main {
        background: #fff;
        padding: 0;
    }
    input, select {
        border: none !important;
        background: transparent !important;
        padding: 0 !important;
        box-shadow: none !important;
        font-size: 12pt !important;
        color: #111 !important;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0px;
        text-overflow: '';
        margin-left: -5px;
    }
    select::-ms-expand {
        display: none;
    }
    label {
        font-weight: normal;
        color: #666;
    }
    .table {
        font-size: 9pt;
    }
}
.screen-list {
    clear: both;
    margin-top: 3.5em;
    .screen {
        float: left;
        width: 22%;
        margin-right: 3.5%;
        margin-bottom: 4em;

        &:nth-child(4n) {
            margin-right: 0;
        }

        &__preview {
            border: 2px solid $light-grey;
            margin-bottom: .5em;
            position: relative;
            padding: 10px;
        }
        &__label {
            width: calc(100% - 50px);
        }
        &__actions {
            cursor: pointer;
            position: relative;
            float: right;
            left: 3px;
            bottom: 4px;
            padding: .15em .025em;
            color: $grey;
            border-radius: 3px;
            font-size: 1.3em;
            background: #fff;
            border: 2px solid transparent;
            width: 30px;
            height: 30px;
            z-index: 3000;
            &.is-open .actions {
                display: block;
            }
            &:hover, &.is-open {
                border-color: $light-grey;
            }
        }

        &__title {
            font-weight: 500;
            @include font-size(1.6);
            line-height: 1.4;
            height: 21px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: .3em;
        }

        &__description {
            color: #aaa;
            line-height: 1.4;
            height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__meta {
            margin-top: .3em;
            border-top: 2px solid $light-grey;
            padding-top: 1em;
        }
    }

    .screen-unreachable span {
        background: $red;
        color: #fff;
        padding: 1px 3px;
        border-radius: 3px;
    }

    .screen-meta {
        width: 100%;
        color: #888;
        @include font-size(1.1);
        td {
            text-align: right;
            vertical-align: middle;
            height: 18px;
        }
        th {
            font-weight: bold;
            text-align: left;
        }
    }

    .empty {
        background: #fafafa;
        text-align: center;
        padding: 3em;

        &__heading {
            @include font-size(2.6);
            margin-bottom: 1em;
            margin-top: .5em;
        }

        &__text {
            @include font-size(1.7);
            color: #666;
            margin-bottom: 2em;
        }

        &__action {
            margin-bottom: 0;
        }
    }
    .actions {
        position: absolute;
        display: none;
        width: 200px;
        border: 2px solid $light-grey;
        box-shadow: 0 2px 5px rgba(0,0,0,.2);
        list-style: none;
        z-index: 2000;
        background: #fff;
        left: 10px;
        top: 10px;
        border-radius: 3px;
        &__action {
            display: block;
            font-size: .7em;
            padding: .65em;
            border-bottom: 1px solid #f0f0f0;
            a {
                display: block;
                &:not(:hover) {
                    color: #aaa;
                }
            }
            &:last-child {
                border-bottom: 0;
            }
            &:hover {
                background: #fafafa;
                a {
                    color: $red;
                }
            }
            .icon {
                margin-right: 5px;
            }
        }
    }
}
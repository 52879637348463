%hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s linear, visibility .15s linear;
}

%visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s linear, visibility .15s linear;
}

.clickable {
    cursor: pointer;
}

.inline {
    display: inline-block !important;
}

.double-line-height {
    line-height: 2;
}

.text {
    margin: $gutter-width;
}

.text-half {
    margin: ($gutter-width/2);
}

.text-double {
    margin: ($gutter-width*2);
}

.float-left {
    float: left;
}

.float-right {
    float: right;
    .button,
    button,
    input,
    select {
        margin-right: -2px !important;
        margin-left: 10px;
    }
}

body.dragging, body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
    display: table;
    td {
        display: table-cell;
    }
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-grey {
    color: #777777 !important;
}

.text-green {
    color: $green !important;
}

.text-orange {
    color: $orange !important;
}

.text-red {
    color: $red !important;
}

.full-width {
    width: 100% !important;
}

.add-bottom {
    margin-bottom: $gutter_width !important;
}

.double-bottom {
    margin-bottom: $gutter_width * 2;
}

.half-bottom {
    margin-bottom: $gutter_width / 2;
}

.remove-bottom {
    margin-bottom: 0;
}

.is-hidden {
    @extend %hidden;
}

.is-loading {
    opacity: .5;
    pointer-events: none;
}

.is-visible {
    @extend %visible;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

div.jsoneditor-tree {
    overflow: visible;
}
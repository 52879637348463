[data-title] {
    &:before, &:after {
        content: "";
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease-out, visibility .25s ease-out;
    }
}

.title-visible {
    &:hover {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
        }
        &:before {
            content: attr(data-title);
            padding: 4px 8px;
            color: #fff;
            position: absolute;
            left: 49%;
            top: -30px;
            white-space: nowrap;
            z-index: 20;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background: rgba(0, 0, 0, .8);
            transform: translateX(-50%);
            font-size: 1.2rem;
            line-height: 1.5;
            font-weight: normal;
        }
        &:after {
            width: 0;
            height: 0;
            left: 50%;
            top: -4px;
            position: absolute;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 5px 4px 0 4px;
            border-color: rgba(0, 0, 0, .8) transparent transparent transparent;
        }
    }
}
.user-avatar {
    border-radius: 50%;
    color: $blue;
    border: 2px solid $blue;
    background: #fff;
    display: inline-block;
    position: absolute;
    right: 11px;
    padding: 9px 6px;
    top: 7px;
    width: 37px;
    height: 36px;
    text-align: center;
}

.nav-user {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 170px;

    .nav-entry {
        a {
            padding: 19px 62px 18px 20px;
            background: #f0f0f0;
            color: #333 !important;
            &:hover {
                color: #333 !important;
            }
        }
        &:hover .nav-sub {
            display: block;
        }
    }

    .nav-sub {
        position: absolute;
        top: 47px;
        left: 0;
        width: 100%;
        padding-top: 8px;
        display: none;

        li {
            display: block;
            padding: 0;
            a {
                color: #333;
                display: block;
                background: #fafafa;
                &:hover {
                    background: #fdfdfd;
                }
            }
        }
    }
}
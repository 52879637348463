/** Layout
---------------------------------------- */
/** Farben
---------------------------------------- */
body::-webkit-scrollbar, html::-webkit-scrollbar, .content-wrapper::-webkit-scrollbar, .item-editor::-webkit-scrollbar, .filebrowser-overlay.is-active .filebrowser__body::-webkit-scrollbar, .fc-scroller::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, .content-wrapper::-webkit-scrollbar-thumb, .item-editor::-webkit-scrollbar-thumb, .filebrowser-overlay.is-active .filebrowser__body::-webkit-scrollbar-thumb, .fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, .content-wrapper::-webkit-scrollbar-thumb, .item-editor::-webkit-scrollbar-thumb, .filebrowser-overlay.is-active .filebrowser__body::-webkit-scrollbar-thumb, .fc-scroller::-webkit-scrollbar-thumb {
  background-color: #CECECE;
}

body::-webkit-scrollbar-track, html::-webkit-scrollbar-track, .content-wrapper::-webkit-scrollbar-track, .item-editor::-webkit-scrollbar-track, .filebrowser-overlay.is-active .filebrowser__body::-webkit-scrollbar-track, .fc-scroller::-webkit-scrollbar-track {
  background-color: #EAEAEA;
  border-left: 1px solid #CECECE;
}

/**
 * Profound Grid
 *
 * Built in Sass (SCSS) this responsive/adaptive grid supports both fixed and fluid layouts,
 * relative and fixed gutters, as well as your choice of semantic markup or generic '.grid-x' classes.
 *
 * The biggest difference to other grid systems is the use of negative margins for column
 * placements, avoiding the subpixel rounding issues that usually break/uglify fluid layouts
 * in some browsers.
 *
 * Nested columns are supported too, without having to reset the grid on each level.
 *
 * Credits/Inspiration:
 * -------------------
 * Semantic Grid: http://www.semantic.gs
 * Susy: http://susy.oddbird.net
 * Negative Grid: http://chrisplaneta.com/freebies/negativegrid-fluid-css-grid-by-chris-planeta/
 *
 * @author Profound Creative Studio
 * @url http://www.profoundgrid.com
 * @url http://www.weareprofound.com
 */
/**
 * Clearfix
 */
/**
 * Legacy Clearfix
 */
/**
 * Establish the grid-containing element.
 */
/**
 * Align an element to the grid.
 */
/**
 * Get Width Only
 */
/**
 * Apply to any column to make it the last one of the current row.
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 5, 2014 */
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/opensans-light-webfont.eot");
  src: url("../../fonts/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-light-webfont.woff") format("woff"), url("../../fonts/opensans-light-webfont.ttf") format("truetype"), url("../../fonts/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/opensans-bold-webfont.eot");
  src: url("../../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-bold-webfont.woff") format("woff"), url("../../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../../fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/opensans-semibold-webfont.eot");
  src: url("../../fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-semibold-webfont.woff") format("woff"), url("../../fonts/opensans-semibold-webfont.ttf") format("truetype"), url("../../fonts/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/opensans-regular-webfont.eot");
  src: url("../../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-regular-webfont.woff") format("woff"), url("../../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../../fonts/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.color-green .header {
  border-bottom-color: #48b059;
}

.color-green .nav-sidebar .active a {
  background-color: #48b059;
}

.color-green a {
  color: #48b059;
}

.color-green a:active {
  color: #69c278;
}

.color-green a:hover {
  color: #57ba67;
}

.color-green .pagination .active span {
  background-color: #48b059;
  border-color: #48b059;
}

.color-green input[type="checkbox"]:checked + label span {
  background-color: #48b059;
  border-color: #48b059;
}

.color-green .btn-primary {
  background: #48b059 !important;
  color: #fff !important;
  border-color: #48b059;
}

.color-green .btn-primary:hover {
  background: #57ba67 !important;
}

.color-green .btn-primary[disabled], .color-green .btn-primary.disabled {
  background: #7bc988;
  border-color: #7bc988;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-green .btn-group .btn-primary:not(:first-child) {
  border-left-color: #419e50;
}

.color-green .dropdown ul li:hover {
  background: #48b059;
}

.color-green .dropdown ul li.is-selected {
  color: #48b059;
}

.color-green .dropdown ul li.is-selected a {
  color: #48b059;
}

.color-green .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-green .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-orange .header {
  border-bottom-color: #faad28;
}

.color-orange .nav-sidebar .active a {
  background-color: #faad28;
}

.color-orange a {
  color: #faad28;
}

.color-orange a:active {
  color: #fbc05a;
}

.color-orange a:hover {
  color: #fbb741;
}

.color-orange .pagination .active span {
  background-color: #faad28;
  border-color: #faad28;
}

.color-orange input[type="checkbox"]:checked + label span {
  background-color: #faad28;
  border-color: #faad28;
}

.color-orange .btn-primary {
  background: #faad28 !important;
  color: #fff !important;
  border-color: #faad28;
}

.color-orange .btn-primary:hover {
  background: #fbb741 !important;
}

.color-orange .btn-primary[disabled], .color-orange .btn-primary.disabled {
  background: #fcca73;
  border-color: #fcca73;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-orange .btn-group .btn-primary:not(:first-child) {
  border-left-color: #f9a30f;
}

.color-orange .dropdown ul li:hover {
  background: #faad28;
}

.color-orange .dropdown ul li.is-selected {
  color: #faad28;
}

.color-orange .dropdown ul li.is-selected a {
  color: #faad28;
}

.color-orange .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-orange .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-red .header {
  border-bottom-color: #ea6048;
}

.color-red .nav-sidebar .active a {
  background-color: #ea6048;
}

.color-red a {
  color: #ea6048;
}

.color-red a:active {
  color: #ef8876;
}

.color-red a:hover {
  color: #ed745f;
}

.color-red .pagination .active span {
  background-color: #ea6048;
  border-color: #ea6048;
}

.color-red input[type="checkbox"]:checked + label span {
  background-color: #ea6048;
  border-color: #ea6048;
}

.color-red .btn-primary {
  background: #ea6048 !important;
  color: #fff !important;
  border-color: #ea6048;
}

.color-red .btn-primary:hover {
  background: #ed745f !important;
}

.color-red .btn-primary[disabled], .color-red .btn-primary.disabled {
  background: #f29c8d;
  border-color: #f29c8d;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-red .btn-group .btn-primary:not(:first-child) {
  border-left-color: #e74c31;
}

.color-red .dropdown ul li:hover {
  background: #ea6048;
}

.color-red .dropdown ul li.is-selected {
  color: #ea6048;
}

.color-red .dropdown ul li.is-selected a {
  color: #ea6048;
}

.color-red .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-red .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-blue .header {
  border-bottom-color: #2db8ba;
}

.color-blue .nav-sidebar .active a {
  background-color: #2db8ba;
}

.color-blue a {
  color: #2db8ba;
}

.color-blue a:active {
  color: #47d1d3;
}

.color-blue a:hover {
  color: #33cbce;
}

.color-blue .pagination .active span {
  background-color: #2db8ba;
  border-color: #2db8ba;
}

.color-blue input[type="checkbox"]:checked + label span {
  background-color: #2db8ba;
  border-color: #2db8ba;
}

.color-blue .btn-primary {
  background: #2db8ba !important;
  color: #fff !important;
  border-color: #2db8ba;
}

.color-blue .btn-primary:hover {
  background: #33cbce !important;
}

.color-blue .btn-primary[disabled], .color-blue .btn-primary.disabled {
  background: #5cd6d8;
  border-color: #5cd6d8;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-blue .btn-group .btn-primary:not(:first-child) {
  border-left-color: #28a4a5;
}

.color-blue .dropdown ul li:hover {
  background: #2db8ba;
}

.color-blue .dropdown ul li.is-selected {
  color: #2db8ba;
}

.color-blue .dropdown ul li.is-selected a {
  color: #2db8ba;
}

.color-blue .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-blue .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-purple .header {
  border-bottom-color: #d7649f;
}

.color-purple .nav-sidebar .active a {
  background-color: #d7649f;
}

.color-purple a {
  color: #d7649f;
}

.color-purple a:active {
  color: #e18db8;
}

.color-purple a:hover {
  color: #dc78ac;
}

.color-purple .pagination .active span {
  background-color: #d7649f;
  border-color: #d7649f;
}

.color-purple input[type="checkbox"]:checked + label span {
  background-color: #d7649f;
  border-color: #d7649f;
}

.color-purple .btn-primary {
  background: #d7649f !important;
  color: #fff !important;
  border-color: #d7649f;
}

.color-purple .btn-primary:hover {
  background: #dc78ac !important;
}

.color-purple .btn-primary[disabled], .color-purple .btn-primary.disabled {
  background: #e7a1c5;
  border-color: #e7a1c5;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-purple .btn-group .btn-primary:not(:first-child) {
  border-left-color: #d25092;
}

.color-purple .dropdown ul li:hover {
  background: #d7649f;
}

.color-purple .dropdown ul li.is-selected {
  color: #d7649f;
}

.color-purple .dropdown ul li.is-selected a {
  color: #d7649f;
}

.color-purple .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-purple .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-grey .header {
  border-bottom-color: #666;
}

.color-grey .nav-sidebar .active a {
  background-color: #666;
}

.color-grey a {
  color: #666;
}

.color-grey a:active {
  color: gray;
}

.color-grey a:hover {
  color: #737373;
}

.color-grey .pagination .active span {
  background-color: #666;
  border-color: #666;
}

.color-grey input[type="checkbox"]:checked + label span {
  background-color: #666;
  border-color: #666;
}

.color-grey .btn-primary {
  background: #666 !important;
  color: #fff !important;
  border-color: #666;
}

.color-grey .btn-primary:hover {
  background: #737373 !important;
}

.color-grey .btn-primary[disabled], .color-grey .btn-primary.disabled {
  background: #8c8c8c;
  border-color: #8c8c8c;
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-grey .btn-group .btn-primary:not(:first-child) {
  border-left-color: #595959;
}

.color-grey .dropdown ul li:hover {
  background: #666;
}

.color-grey .dropdown ul li.is-selected {
  color: #666;
}

.color-grey .dropdown ul li.is-selected a {
  color: #666;
}

.color-grey .dropdown ul li.is-selected a:hover {
  color: #fff;
}

.color-grey .dropdown ul li.is-selected:hover {
  color: #fff !important;
}

.color-grey .header {
  border-bottom-color: #fff;
}

.is-hidden, input[type="submit"].btn-delete,
input[type="reset"].btn-delete,
input[type="button"].btn-delete,
button.btn-delete,
.btn.btn-delete {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s linear, visibility .15s linear;
}

.is-visible, input[type="submit"].btn-delete.is-visible,
input[type="reset"].btn-delete.is-visible,
input[type="button"].btn-delete.is-visible,
button.btn-delete.is-visible,
.btn.btn-delete.is-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s linear, visibility .15s linear;
}

.clickable {
  cursor: pointer;
}

.inline {
  display: inline-block !important;
}

.double-line-height {
  line-height: 2;
}

.text {
  margin: 1%;
}

.text-half {
  margin: 0.5%;
}

.text-double {
  margin: 2%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-right .button,
.float-right button,
.float-right input,
.float-right select {
  margin-right: -2px !important;
  margin-left: 10px;
}

body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
  display: table;
}

.dragged td {
  display: table-cell;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-grey {
  color: #777777 !important;
}

.text-green {
  color: #48b059 !important;
}

.text-orange {
  color: #faad28 !important;
}

.text-red {
  color: #ea6048 !important;
}

.full-width {
  width: 100% !important;
}

.add-bottom {
  margin-bottom: 1% !important;
}

.double-bottom {
  margin-bottom: 2%;
}

.half-bottom {
  margin-bottom: 0.5%;
}

.remove-bottom {
  margin-bottom: 0;
}

.is-loading {
  opacity: .5;
  pointer-events: none;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

div.jsoneditor-tree {
  overflow: visible;
}

@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-ms-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes flashFadeInDown {
  0% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
}

@-moz-keyframes flashFadeInDown {
  0% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
}

@-ms-keyframes flashFadeInDown {
  0% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
}

@-o-keyframes flashFadeInDown {
  0% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
}

@keyframes flashFadeInDown {
  0% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
}

@-webkit-keyframes flashFadeOutUp {
  0% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
}

@-moz-keyframes flashFadeOutUp {
  0% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
}

@-ms-keyframes flashFadeOutUp {
  0% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
}

@-o-keyframes flashFadeOutUp {
  0% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
}

@keyframes flashFadeOutUp {
  0% {
    opacity: 1;
    visibility: visible;
    top: 10px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }
}

@-webkit-keyframes assetIntro {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes assetIntro {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-ms-keyframes assetIntro {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes assetIntro {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes assetIntro {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

main {
  display: block;
}

body, html {
  height: 100%;
  min-height: 100%;
  color: #333;
}

.content {
  float: left;
  margin-right: -100%;
  width: 57.92%;
  margin-left: 33.67%;
  margin-top: 40px;
}

.content.no-sidebar {
  float: left;
  margin-right: -100%;
  width: 83.17%;
  margin-left: 8.42%;
}

.fullbleed {
  background: #EEE;
}

.fullbleed .content {
  margin-top: 0;
  height: 100%;
  float: left;
  margin-right: -100%;
  width: 100%;
  margin-left: 0%;
}

.fullbleed .header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.fixed-header {
  padding-top: 55px;
}

.fixed-header .header {
  position: fixed;
  width: 100%;
  top: 0;
}

.sidebar {
  float: left;
  margin-right: -100%;
  width: 15.83%;
  margin-left: 8.42%;
  margin-top: 40px;
}

.header {
  font-size: 0px;
  font-size: 0rem;
  background: #F1F1F1;
  height: 55px;
  border-bottom: 4px solid;
  position: relative;
  z-index: 8000;
}

.nav-header {
  font-size: 14px;
  font-size: 1.4rem;
  float: left;
  margin-right: -100%;
  width: 49.5%;
  margin-left: 8.42%;
}

.header-actions {
  font-size: 14px;
  font-size: 1.4rem;
  float: left;
  margin-right: -100%;
  width: 41.08%;
  margin-left: 58.92%;
  text-align: right;
  height: 50px;
  line-height: 50px;
  padding-right: 20px;
}

.header-actions > * {
  line-height: 50px;
  margin-bottom: 0;
}

.login {
  float: left;
  margin-right: -100%;
  width: 32.67%;
  margin-left: 33.67%;
  border: 1px solid #aaa;
  padding: 1%;
}

.wrapper {
  min-height: 100%;
  margin: 0 auto -220px;
}

.wrapper:after {
  content: "";
  display: block;
}

.logo {
  float: left;
  margin-right: -100%;
  width: 7.42%;
  padding: 8px;
}

.content-wrapper {
  clear: both;
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100% - 55px);
}

.footer {
  clear: both;
  background: #f1f1f1;
  padding-top: 40px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
}

.footer__help {
  margin-left: 8.42%;
  float: left;
  margin-right: -100%;
  width: 32.67%;
  padding-left: 100px;
  background: url("../images/icon-support.png") no-repeat 0 5px;
}

.footer__about {
  margin-left: 42.08%;
  float: left;
  margin-right: -100%;
  width: 24.25%;
}

.footer a:link,
.footer a:visited,
.footer a:hover,
.footer a:active {
  color: #333 !important;
}

.timeline-editor {
  margin-top: 40px;
}

.col-50 {
  float: left;
}

.col-50--right {
  width: 49%;
}

.col-50--left {
  width: 49%;
  margin-right: 2%;
}

html {
  font-size: 62.5%;
  min-height: 100%;
}

body {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}

body.dragging,
body.dragging * {
  cursor: move !important;
}

a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
  color: inherit;
}

h1 {
  font-size: 39px;
  font-size: 3.9rem;
  line-height: 50px;
  margin-bottom: 14px;
}

h2 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #444;
}

h3 {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 34px;
  margin-bottom: 8px;
}

h4 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 30px;
  margin-bottom: 4px;
}

h5 {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 21px;
}

p {
  margin: 0 0 20px;
  line-height: 1.6;
  color: #333;
  font-weight: 300;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 10px 0 20px;
}

.main h1 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 300;
}

.main h2 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main h3 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
}

.main a:link,
.main a:active,
.main a:visited {
  color: #005580;
}

.main a:link.button,
.main a:active.button,
.main a:visited.button {
  color: #333;
}

.main a:hover {
  color: #0077b3;
}

.page-heading {
  color: #484848;
  font-weight: 500;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.6;
}

.page-heading--float-left {
  float: left;
  margin-right: 20px;
}

.page-heading--float-left + .btn {
  margin-top: .4em;
}

.page-subheading {
  color: #888;
  margin-top: -16px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
}

@page {
  size: A4;
  margin: 1cm;
}

@page :first {
  margin: 0cm 1cm 1cm;
}

@media print {
  .sidebar-left,
  .toolbar,
  .hide-on-print {
    display: none !important;
  }
  .page-wrapper {
    background: none !important;
  }
  .page-title {
    margin: 40px 0;
    border-bottom: 1px solid #888;
  }
  .page-title img {
    display: none;
  }
  .page-title h2 {
    margin-left: 0;
  }
  .main {
    display: block;
    margin: 0;
    width: 100%;
    min-height: 26.7cm;
    border-radius: 0;
  }
  .content, .main {
    background: #fff;
    padding: 0;
  }
  input, select {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    font-size: 12pt !important;
    color: #111 !important;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    margin-left: -5px;
  }
  select::-ms-expand {
    display: none;
  }
  label {
    font-weight: normal;
    color: #666;
  }
  .table {
    font-size: 9pt;
  }
}

.pagination {
  float: right;
}

.pagination li {
  display: inline-block;
  margin-right: 2px;
}

.pagination li.active span {
  color: #fff;
  font-weight: bold;
}

.pagination a, .pagination span {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
  padding: 5px 8px;
  line-height: 1;
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  transition: .3s ease border-color;
}

.pagination a:hover, .pagination span:hover {
  border-color: #e0e0e0;
}

.pagination .disabled {
  display: none;
}

.flash {
  position: fixed;
  top: 10px;
  left: 50%;
  min-width: 22%;
  max-width: 1000px;
  white-space: nowrap;
  text-align: center;
  padding: 8px 10px;
  background: #fefefe;
  visibility: visible;
  opacity: 1;
  z-index: 20000;
  border-radius: 2px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
  animation: flashFadeInDown .5s;
}

.flash.is-hidden {
  animation: flashFadeOutUp 1s;
  top: -150%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.flash--error, .flash--danger {
  background: #ea6048;
  color: #fff;
}

.flash--success {
  background: #48b059;
  color: #fff;
}

.flash--warning {
  background: #faad28;
  color: #221601;
}

.flash--shell {
  opacity: 0;
  top: -150%;
  display: none;
}

.nav-header__nav {
  font-size: 0px;
  font-size: 0rem;
}

.nav-header__nav li {
  display: inline-block;
}

.nav-header__nav li:hover a,
.nav-header__nav li.active a {
  color: #fff;
}

.nav-header__nav a {
  font-size: 14px;
  font-size: 1.4rem;
  color: #333;
  font-weight: 500;
  display: inline-block;
  padding: 20px 20px 17px;
  transition: .2s ease background-color, .2s ease color;
}

.nav-entry__screens.active a, .nav-entry__screens:hover a {
  background: #ea6048;
}

.nav-entry__slideshows.active a, .nav-entry__slideshows:hover a {
  background: #faad28;
}

.nav-entry__timeline.active a, .nav-entry__timeline:hover a {
  background: #2db8ba;
}

.nav-entry__datasets.active a, .nav-entry__datasets:hover a {
  background: #48b059;
}

.nav-sidebar {
  border-radius: 4px;
}

.nav-sidebar ul {
  padding: 12px 0;
}

.nav-sidebar li {
  font-size: 14px;
  font-size: 1.4rem;
}

.nav-sidebar a {
  color: #484848;
  padding: 10px 12px 10px 36px;
  font-weight: 500;
  display: block;
  border-radius: 4px;
}

.nav-sidebar a:hover {
  background: #eee;
  color: inherit;
}

.nav-sidebar .icon {
  display: inline-block;
  width: 27px;
  margin-left: -31px;
  text-align: center;
}

.nav-sidebar .badge {
  float: right;
  font-weight: normal;
}

.nav-sidebar .active a {
  color: #fff;
}

.dropdown {
  border-radius: 4px;
  background: #fafafa;
  border: 1px solid #e0e0e0;
  color: #444;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  appearance: button;
  cursor: pointer;
  display: inline-block;
  min-width: 180px;
  vertical-align: middle;
  user-select: none;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 1000;
}

.dropdown a {
  color: #444;
  display: block;
  padding: 8px 12px;
}

.dropdown li {
  padding: 0;
}

.dropdown li.dropdown__label {
  font-size: 11px;
  font-size: 1.1rem;
  background: #f5f5f5;
  cursor: default;
  padding: 3px 12px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.dropdown li.dropdown__label:hover {
  background: #f5f5f5 !important;
  color: #444 !important;
}

.dropdown li.dropdown__active {
  border-radius: 4px;
  padding: 8px 12px;
}

.dropdown li.dropdown__active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #2b2b2b transparent transparent transparent;
  position: absolute;
  right: 12px;
  top: 15px;
}

.dropdown li ul li:hover {
  color: #fff;
}

.dropdown li ul li:hover a {
  color: #fff;
}

.dropdown ul {
  position: absolute;
  background: inherit;
  margin: 0;
  left: -1px;
  width: 100%;
  width: calc(100% + 2px);
  z-index: 1000;
  top: 34px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0 4px 4px 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.11);
}

.dropdown:hover {
  background: #fff;
}

.dropdown.is-open {
  border-radius: 4px 4px 0 0;
}

.dropdown.is-open ul {
  opacity: 1;
  visibility: visible;
}

.sort-selector {
  min-width: 50px;
}

.sort-selector a {
  padding: 10px 12px;
}

.sort-selector li.dropdown__active {
  padding: 8px 6px;
}

.sort-selector li.dropdown__active:after {
  top: 14px;
  right: 10px;
}

.sort-selector ul {
  min-width: 180px;
  top: 30px;
}

.item-selector {
  margin-top: 6px;
}

.item-list {
  margin-bottom: 20px;
}

.item-list--split {
  width: 50%;
  float: left;
}

.item-list--split .item-list__pagination {
  margin-right: 10px;
}

.item-list__item, .item-list__header {
  border-bottom: 1px solid #E0E0E0;
  padding: 18px 0;
  position: relative;
}

.item-list__item > div, .item-list__header > div {
  display: inline-block;
  vertical-align: top;
}

.item-list__item--no-description .item-list__title {
  margin-bottom: 0;
}

.item-list__item--no-description .item-list__item-actions {
  top: 13px;
}

.item-list__item.is-selected {
  background: #fafafa;
}

.item-list__header {
  border-bottom: 1px solid #ddd;
}

.item-list__pagination {
  float: right;
}

.item-list__actions {
  margin-top: -8px;
}

.item-list__checkbox {
  width: 50px;
}

.item-list__image {
  width: 120px;
}

.item-list__meta {
  width: 94%;
  width: calc(100% - 55px);
}

.item-list__item-actions {
  position: absolute;
  right: 12px;
  top: 24px;
}

.item-list__title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
}

.item-list__description {
  font-weight: normal;
}

.item-list__secondary-action {
  float: right;
}

.item-list p {
  font-weight: normal;
  margin-bottom: 0;
  color: #666;
}

.item-list__item--compact .item-list__title {
  margin-bottom: 0;
  line-height: 1.3;
}

.item-list__item--compact .item-list__checkbox {
  position: relative;
  top: 6px;
}

.item-editor {
  background: #fafafa;
  float: left;
  width: 800px;
  height: 100vh;
  top: 0;
  padding-top: 160px;
  box-shadow: inset 1px 0 9px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 50%;
  overflow-y: auto;
}

.item-editor__content {
  overflow: auto;
}

.item-editor__loader {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-out;
}

.item-editor__loader.is-visible {
  opacity: 1;
  visibility: visible;
}

.item-editor__blank {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 1em;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  color: #666;
  top: 50%;
  position: absolute;
  width: 440px;
  left: 50%;
  transform: translateX(-50%);
}

.item-editor__blank--static {
  transform: none;
  width: 100%;
  margin: 2em 0;
  position: relative;
  left: 0;
}

.item-editor__blank i {
  position: absolute;
  left: 10px;
}

.item-editor input[type="text"],
.item-editor input[type="password"],
.item-editor input[type="email"],
.item-editor input[type="url"],
.item-editor input[type="date"],
.item-editor input[type="datetime"],
.item-editor input[type="time"],
.item-editor input[type="range"],
.item-editor input[type="number"],
.item-editor input[type="search"],
.item-editor input[type="tel"],
.item-editor input[type="file"],
.item-editor textarea,
.item-editor select {
  width: 100%;
}

.item-editor-dropzone.dropzone {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 1em;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  color: #666;
}

.item-editor-dropzone.dropzone .dz-message {
  margin-top: 3.6em;
  color: #666;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="datetime"],
input[type="time"],
input[type="range"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="file"],
textarea,
select {
  display: block;
  border-radius: 4px;
  font: 13px 'Open Sans', Arial, sans-serif;
  border: 2px solid #e0e0e0;
  padding: 7px 5px;
  outline: none;
  color: #777;
  margin: 0;
  width: 310px;
  max-width: 100%;
  background: #fff;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="time"]:focus,
input[type="range"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="file"]:focus,
textarea:focus,
select:focus {
  color: #333;
  border-color: #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

input[type="text"].xxx-small,
input[type="password"].xxx-small,
input[type="email"].xxx-small,
input[type="url"].xxx-small,
input[type="date"].xxx-small,
input[type="datetime"].xxx-small,
input[type="time"].xxx-small,
input[type="range"].xxx-small,
input[type="number"].xxx-small,
input[type="search"].xxx-small,
input[type="tel"].xxx-small,
input[type="file"].xxx-small,
textarea.xxx-small,
select.xxx-small {
  width: 7.42%;
  text-align: center;
}

input[type="text"].xx-small,
input[type="password"].xx-small,
input[type="email"].xx-small,
input[type="url"].xx-small,
input[type="date"].xx-small,
input[type="datetime"].xx-small,
input[type="time"].xx-small,
input[type="range"].xx-small,
input[type="number"].xx-small,
input[type="search"].xx-small,
input[type="tel"].xx-small,
input[type="file"].xx-small,
textarea.xx-small,
select.xx-small {
  width: 15.83%;
}

input[type="text"].x-small,
input[type="password"].x-small,
input[type="email"].x-small,
input[type="url"].x-small,
input[type="date"].x-small,
input[type="datetime"].x-small,
input[type="time"].x-small,
input[type="range"].x-small,
input[type="number"].x-small,
input[type="search"].x-small,
input[type="tel"].x-small,
input[type="file"].x-small,
textarea.x-small,
select.x-small {
  width: 24.25%;
}

input[type="text"].small,
input[type="password"].small,
input[type="email"].small,
input[type="url"].small,
input[type="date"].small,
input[type="datetime"].small,
input[type="time"].small,
input[type="range"].small,
input[type="number"].small,
input[type="search"].small,
input[type="tel"].small,
input[type="file"].small,
textarea.small,
select.small {
  width: 32.67%;
}

input[type="text"].xxx-large,
input[type="password"].xxx-large,
input[type="email"].xxx-large,
input[type="url"].xxx-large,
input[type="date"].xxx-large,
input[type="datetime"].xxx-large,
input[type="time"].xxx-large,
input[type="range"].xxx-large,
input[type="number"].xxx-large,
input[type="search"].xxx-large,
input[type="tel"].xxx-large,
input[type="file"].xxx-large,
textarea.xxx-large,
select.xxx-large {
  width: 66.33%;
}

input[type="text"].xx-large,
input[type="password"].xx-large,
input[type="email"].xx-large,
input[type="url"].xx-large,
input[type="date"].xx-large,
input[type="datetime"].xx-large,
input[type="time"].xx-large,
input[type="range"].xx-large,
input[type="number"].xx-large,
input[type="search"].xx-large,
input[type="tel"].xx-large,
input[type="file"].xx-large,
textarea.xx-large,
select.xx-large {
  width: 57.92%;
}

input[type="text"].x-large,
input[type="password"].x-large,
input[type="email"].x-large,
input[type="url"].x-large,
input[type="date"].x-large,
input[type="datetime"].x-large,
input[type="time"].x-large,
input[type="range"].x-large,
input[type="number"].x-large,
input[type="search"].x-large,
input[type="tel"].x-large,
input[type="file"].x-large,
textarea.x-large,
select.x-large {
  width: 49.5%;
}

input[type="text"].large,
input[type="password"].large,
input[type="email"].large,
input[type="url"].large,
input[type="date"].large,
input[type="datetime"].large,
input[type="time"].large,
input[type="range"].large,
input[type="number"].large,
input[type="search"].large,
input[type="tel"].large,
input[type="file"].large,
textarea.large,
select.large {
  width: 41.08%;
}

input[type="text"].activation-code,
input[type="password"].activation-code,
input[type="email"].activation-code,
input[type="url"].activation-code,
input[type="date"].activation-code,
input[type="datetime"].activation-code,
input[type="time"].activation-code,
input[type="range"].activation-code,
input[type="number"].activation-code,
input[type="search"].activation-code,
input[type="tel"].activation-code,
input[type="file"].activation-code,
textarea.activation-code,
select.activation-code {
  font-size: 38px;
  font-family: "Courier New", monospace;
  padding: 5px 10px 3px;
}

input[type="range"]:focus {
  box-shadow: none;
}

textarea {
  min-height: 60px;
}

.table input, .table select {
  margin-bottom: 0;
}

label,
legend {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
  color: #555;
  font-weight: bold;
  margin-bottom: 6px;
  cursor: pointer;
  white-space: nowrap;
}

label span,
legend span {
  font-weight: normal;
  font-size: 13px;
  font-size: 1.3rem;
  padding-left: 10px;
  color: #AAA;
  white-space: nowrap;
}

label.not-bold,
legend.not-bold {
  font-weight: normal;
}

.form-control {
  margin-bottom: 20px;
}

.form-control-inline {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.error label, .error legend {
  color: #e74c3c !important;
}

.error label span, .error legend span {
  background: #e74c3c;
  color: #fff;
  padding: 0 3px 0 3px;
  margin-left: 15px;
  font-size: 90%;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: bold;
}

.error input[type="text"],
.error input[type="password"],
.error input[type="email"],
.error input[type="url"],
.error input[type="date"],
.error input[type="number"],
.error input[type="datetime"],
.error input[type="time"],
.error input[type="range"],
.error input[type="file"],
.error input[type="tel"],
.error input[type="search"],
.error textarea,
.error select {
  color: #e74c3c !important;
  border-color: #e74c3c !important;
}

.error input[type="text"]:focus,
.error input[type="password"]:focus,
.error input[type="email"]:focus,
.error input[type="url"]:focus,
.error input[type="date"]:focus,
.error input[type="number"]:focus,
.error input[type="datetime"]:focus,
.error input[type="time"]:focus,
.error input[type="range"]:focus,
.error input[type="file"]:focus,
.error input[type="tel"]:focus,
.error input[type="search"]:focus,
.error textarea:focus,
.error select:focus {
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.35);
}

.fakebox {
  border: 2px solid #e0e0e0;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transition: .2s ease border-color;
  cursor: pointer;
}

.fakebox:hover {
  border-color: #bbb;
}

input[type="checkbox"] {
  display: none;
  user-select: none;
}

input[type="checkbox"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 12px 0 0;
  vertical-align: middle;
  transition: .2s ease border-color, .2s ease background-color;
  border: 2px solid #e0e0e0;
  border-radius: 2px;
  background: none center center no-repeat #fff;
  cursor: pointer;
  user-select: none;
}

input[type="checkbox"] + label span:hover {
  border-color: #bbb;
}

input[type="checkbox"]:checked + label span {
  background-image: url("../../images/check.png");
  font-weight: normal;
}

input[type="checkbox"] + label {
  font-weight: normal;
}

input[type="checkbox"].checkbox--small + label span {
  width: 17px;
  height: 17px;
  border-width: 1px;
}

.filebrowser-overlay.is-active .filebrowser__body {
  position: absolute;
  left: 50%;
  width: 80vw;
  height: 99vh;
  height: calc(100vh - 52px);
  background: #FFF;
  padding: 1em;
  box-sizing: border-box;
  font-size: 1.4rem;
  border-radius: 2px;
  box-shadow: 0 0 31px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  will-change: transform;
  transform: translate(-50%, 0%);
}

.filebrowser-overlay.is-active .file-list__entry--empty {
  width: calc(100% - 2.1em) !important;
}

.breadcrumbs {
  width: 100%;
  margin-bottom: 1%;
  padding: .5em 0;
  font-size: 12px;
  font-size: 1.2rem;
}

.breadcrumbs > li {
  display: inline;
  margin-right: 10px;
}

.breadcrumbs a {
  padding: .25em .5em;
  display: inline-block;
  background: #f0f0f0;
  border-radius: 2px;
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-toolbar {
  float: left;
  margin-right: 2em;
}

.file-list {
  margin-top: 30px;
}

.file-list--loading {
  opacity: .6;
}

.file-list__loading-indicator {
  text-align: center;
  margin-top: 100px;
}

.file-list .placeholder {
  height: 0 !important;
  opacity: 0;
  visibility: hidden;
}

.file-list a {
  color: #555;
}

.file-list a:hover {
  color: #333;
}

.file-list__entry--empty {
  text-align: center;
  padding: 45px 0 40px;
  width: 100% !important;
  height: auto !important;
  border: 1px solid #EDF0F1;
  background: #F6F7F9;
  display: inline-block;
  margin: 1em;
  border-radius: 4px;
}

.file-list__entry--empty .btn {
  margin-left: 20px;
}

.file-list--list .file-list__entry {
  border-bottom: 1px solid #EAEAEA;
  padding: 11px 0;
  display: block;
  height: 46px;
}

.file-list--list .file-list__entry > div {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.file-list--list .file__checkbox {
  top: 4px;
  left: 3px;
  position: relative;
}

.file-list--list .file__icon {
  margin: 2px 15px 0;
}

.file-list--list .file__icon img {
  width: 16px;
}

.file-list--list .file__size {
  margin-top: 5px;
  float: right;
  font-size: 12px;
  font-size: 1.2rem;
}

.file-list--list .file__thumbnail {
  display: none !important;
}

.file-list--grid {
  text-align: justify;
  margin: 30px -1em;
}

.file-list--grid .file-list__entry {
  border: 1px solid #EDF0F1;
  background: #F6F7F9;
  display: inline-block;
  margin: 1em;
  width: 30%;
  height: 100px;
  position: relative;
  border-radius: 4px;
}

.file-list--grid .file-list__entry > div {
  position: absolute;
}

.file-list--grid .file__checkbox {
  right: -8px;
  top: 5px;
  z-index: 2000;
}

.file-list--grid .file__icon {
  display: none;
}

.file-list--grid .file__thumbnail {
  left: 0;
  top: 0;
  width: 100px;
  height: 98px;
  border-right: 1px solid #EDF0F1;
  cursor: pointer;
}

.file-list--grid .file__thumbnail-container {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.file-list--grid .file__thumbnail img {
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.file-list--grid .file__name {
  left: 120px;
  top: 28px;
  width: 50%;
  width: calc(100% - 140px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
}

.file-list--grid .file__size {
  position: absolute;
  left: 120px;
  top: 57px;
  font-size: 11px;
  font-size: 1.1rem;
  color: #aaa;
}

.file-list--grid .file--dir .file__thumbnail {
  cursor: pointer;
}

.file-list--grid .file--dir .file__thumbnail:hover {
  background: #f9fafb;
}

.file-list--grid.file-list--thumbs-only .file-list__entry {
  width: 150px;
  height: 150px;
}

.file-list--grid.file-list--thumbs-only .file__thumbnail {
  width: 150px;
  height: 148px;
}

.file-list--grid.file-list--thumbs-only .file__checkbox {
  right: 8px;
}

.file-list--flex {
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.file-list--flex .file-list__entry {
  width: 20% !important;
  height: 150px !important;
  flex: 1 1 auto;
}

.file-list--flex .file-list__entry.placeholder {
  height: 0 !important;
}

.file-list--flex .file__thumbnail {
  width: 100% !important;
  height: 100% !important;
}

.file__loader {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
}

.file-list__entry.not-deployed .file__loader + img {
  opacity: .4;
}

.upload-queue {
  width: 500px;
  min-height: 10vh;
  max-height: 40vh;
  overflow: hidden;
  position: fixed;
  right: 40px;
  bottom: -40vh;
  background: #fefefe;
  border-top: 1px solid #CFCFCF;
  border-left: 1px solid #CFCFCF;
  border-right: 1px solid #CFCFCF;
  border-radius: 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  transition: bottom .3s ease, opacity .3s ease;
}

.upload-queue.is-visible {
  bottom: -1px;
}

.upload-queue__header {
  background: #EDEDED;
  color: #333;
  padding: .75em .5em;
  font-weight: 500;
}

.upload-queue__header .header-action-hide {
  float: right;
  cursor: pointer;
}

.upload-queue__list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 30vh;
}

.upload-queue__entry {
  background: #f8f8f8;
  border-top: 1px solid #eee;
  padding: 0.75em 0.5em;
  position: relative;
  height: 2.6em;
  font-size: 13px;
  font-size: 1.3rem;
}

.upload-queue__entry.dz-success .action-cancel {
  display: none;
}

.upload-queue__entry.dz-success .status-okay {
  display: block;
}

.upload-queue__entry.dz-success .filename {
  color: #4B7728;
}

.upload-queue__entry.dz-success .progressbar {
  background: #B4E4A1;
  border-right: 0;
}

.upload-queue__entry.dz-error .action-cancel {
  color: #ea6048;
}

.upload-queue__entry.dz-error .filename {
  color: #ea6048;
}

.upload-queue__entry.dz-error .progressbar {
  background: #F2DEDE;
  border-right: 0;
}

.upload-queue .thumbnail {
  width: 20px;
  position: relative;
  z-index: 1000;
  margin-right: 8px;
  background: #F0F0F0;
  height: 20px;
  display: inline-block;
  margin-top: -3px;
}

.upload-queue .filename {
  display: inline-block;
  position: relative;
  z-index: 1000;
  overflow: hidden;
  width: 80%;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 3px;
  color: #555;
}

.upload-queue .action-cancel {
  float: right;
  position: relative;
  z-index: 1000;
}

.upload-queue .action-cancel:hover {
  background: #DDD;
  border-radius: 50%;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.upload-queue .status-okay {
  float: right;
  position: relative;
  z-index: 1000;
  display: none;
  color: #4B7728;
  cursor: pointer;
}

.upload-queue .progressbar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #fff;
  border-right: 2px solid #bbb;
  z-index: 0;
  transition: width .5s ease;
}

tr.add-new {
  background-color: #eee;
}

.dataset--fullbleed .content {
  padding: 10px;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.btn {
  border-radius: 4px;
  background: #fafafa;
  color: #444 !important;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  font-weight: normal;
  font-family: 'Open Sans', Arial, sans-serif;
  padding: 8px 12px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  position: relative;
  white-space: nowrap;
  transition: background .2s ease-out;
}

input[type="submit"]:hover, input[type="submit"].btn--pressed,
input[type="reset"]:hover,
input[type="reset"].btn--pressed,
input[type="button"]:hover,
input[type="button"].btn--pressed,
button:hover,
button.btn--pressed,
.btn:hover,
.btn.btn--pressed {
  background: #eee;
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.btn:active {
  transform: scale(0.98);
}

input[type="submit"][disabled], input[type="submit"].disabled,
input[type="reset"][disabled],
input[type="reset"].disabled,
input[type="button"][disabled],
input[type="button"].disabled,
button[disabled],
button.disabled,
.btn[disabled],
.btn.disabled {
  cursor: default;
  background: #f0f0f0;
  border-color: #eee;
  color: #888 !important;
}

input[type="submit"].btn-green,
input[type="reset"].btn-green,
input[type="button"].btn-green,
button.btn-green,
.btn.btn-green {
  background: #48b059;
  color: #fff !important;
  border-color: #48b059;
}

input[type="submit"].btn-green:hover,
input[type="reset"].btn-green:hover,
input[type="button"].btn-green:hover,
button.btn-green:hover,
.btn.btn-green:hover {
  background: #57ba67;
}

input[type="submit"].btn-green[disabled], input[type="submit"].btn-green.disabled,
input[type="reset"].btn-green[disabled],
input[type="reset"].btn-green.disabled,
input[type="button"].btn-green[disabled],
input[type="button"].btn-green.disabled,
button.btn-green[disabled],
button.btn-green.disabled,
.btn.btn-green[disabled],
.btn.btn-green.disabled {
  background: #a0d8a9 !important;
  border-color: #a0d8a9 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn-orange,
input[type="reset"].btn-orange,
input[type="button"].btn-orange,
button.btn-orange,
.btn.btn-orange {
  background: #faad28;
  color: #fff !important;
  border-color: #faad28;
}

input[type="submit"].btn-orange:hover,
input[type="reset"].btn-orange:hover,
input[type="button"].btn-orange:hover,
button.btn-orange:hover,
.btn.btn-orange:hover {
  background: #fbb741;
}

input[type="submit"].btn-orange[disabled], input[type="submit"].btn-orange.disabled,
input[type="reset"].btn-orange[disabled],
input[type="reset"].btn-orange.disabled,
input[type="button"].btn-orange[disabled],
input[type="button"].btn-orange.disabled,
button.btn-orange[disabled],
button.btn-orange.disabled,
.btn.btn-orange[disabled],
.btn.btn-orange.disabled {
  background: #fddda5 !important;
  border-color: #fddda5 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn-red,
input[type="reset"].btn-red,
input[type="button"].btn-red,
button.btn-red,
.btn.btn-red {
  background: #ea6048;
  color: #fff !important;
  border-color: #ea6048;
}

input[type="submit"].btn-red:hover,
input[type="reset"].btn-red:hover,
input[type="button"].btn-red:hover,
button.btn-red:hover,
.btn.btn-red:hover {
  background: #ed745f;
}

input[type="submit"].btn-red[disabled], input[type="submit"].btn-red.disabled,
input[type="reset"].btn-red[disabled],
input[type="reset"].btn-red.disabled,
input[type="button"].btn-red[disabled],
input[type="button"].btn-red.disabled,
button.btn-red[disabled],
button.btn-red.disabled,
.btn.btn-red[disabled],
.btn.btn-red.disabled {
  background: #f7c3ba !important;
  border-color: #f7c3ba !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn-blue,
input[type="reset"].btn-blue,
input[type="button"].btn-blue,
button.btn-blue,
.btn.btn-blue {
  background: #2db8ba;
  color: #fff !important;
  border-color: #2db8ba;
}

input[type="submit"].btn-blue:hover,
input[type="reset"].btn-blue:hover,
input[type="button"].btn-blue:hover,
button.btn-blue:hover,
.btn.btn-blue:hover {
  background: #33cbce;
}

input[type="submit"].btn-blue[disabled], input[type="submit"].btn-blue.disabled,
input[type="reset"].btn-blue[disabled],
input[type="reset"].btn-blue.disabled,
input[type="button"].btn-blue[disabled],
input[type="button"].btn-blue.disabled,
button.btn-blue[disabled],
button.btn-blue.disabled,
.btn.btn-blue[disabled],
.btn.btn-blue.disabled {
  background: #85e0e1 !important;
  border-color: #85e0e1 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn-purple,
input[type="reset"].btn-purple,
input[type="button"].btn-purple,
button.btn-purple,
.btn.btn-purple {
  background: #d7649f;
  color: #fff !important;
  border-color: #d7649f;
}

input[type="submit"].btn-purple:hover,
input[type="reset"].btn-purple:hover,
input[type="button"].btn-purple:hover,
button.btn-purple:hover,
.btn.btn-purple:hover {
  background: #dc78ac;
}

input[type="submit"].btn-purple[disabled], input[type="submit"].btn-purple.disabled,
input[type="reset"].btn-purple[disabled],
input[type="reset"].btn-purple.disabled,
input[type="button"].btn-purple[disabled],
input[type="button"].btn-purple.disabled,
button.btn-purple[disabled],
button.btn-purple.disabled,
.btn.btn-purple[disabled],
.btn.btn-purple.disabled {
  background: #f1c9de !important;
  border-color: #f1c9de !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn-grey,
input[type="reset"].btn-grey,
input[type="button"].btn-grey,
button.btn-grey,
.btn.btn-grey {
  background: #666;
  color: #fff !important;
  border-color: #666;
}

input[type="submit"].btn-grey:hover,
input[type="reset"].btn-grey:hover,
input[type="button"].btn-grey:hover,
button.btn-grey:hover,
.btn.btn-grey:hover {
  background: #737373;
}

input[type="submit"].btn-grey[disabled], input[type="submit"].btn-grey.disabled,
input[type="reset"].btn-grey[disabled],
input[type="reset"].btn-grey.disabled,
input[type="button"].btn-grey[disabled],
input[type="button"].btn-grey.disabled,
button.btn-grey[disabled],
button.btn-grey.disabled,
.btn.btn-grey[disabled],
.btn.btn-grey.disabled {
  background: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="submit"].btn--borderless,
input[type="reset"].btn--borderless,
input[type="button"].btn--borderless,
button.btn--borderless,
.btn.btn--borderless {
  border: none;
  background: none;
}

input[type="submit"].btn--borderless:hover,
input[type="reset"].btn--borderless:hover,
input[type="button"].btn--borderless:hover,
button.btn--borderless:hover,
.btn.btn--borderless:hover {
  background: #f0f0f0;
}

input[type="submit"].btn--borderless[disabled], input[type="submit"].btn--borderless.disabled,
input[type="reset"].btn--borderless[disabled],
input[type="reset"].btn--borderless.disabled,
input[type="button"].btn--borderless[disabled],
input[type="button"].btn--borderless.disabled,
button.btn--borderless[disabled],
button.btn--borderless.disabled,
.btn.btn--borderless[disabled],
.btn.btn--borderless.disabled {
  color: #999 !important;
}

input[type="submit"].btn--light,
input[type="reset"].btn--light,
input[type="button"].btn--light,
button.btn--light,
.btn.btn--light {
  border-color: #e1e1e5;
  background: transparent;
  color: #999999 !important;
}

input[type="submit"].btn--light:hover,
input[type="reset"].btn--light:hover,
input[type="button"].btn--light:hover,
button.btn--light:hover,
.btn.btn--light:hover {
  color: #666 !important;
  border-color: #d4d4d9;
}

input[type="submit"].btn--small,
input[type="reset"].btn--small,
input[type="button"].btn--small,
button.btn--small,
.btn.btn--small {
  padding: 4px 10px;
}

input[type="submit"] .icon,
input[type="reset"] .icon,
input[type="button"] .icon,
button .icon,
.btn .icon {
  margin-right: 6px;
  margin-left: -4px;
}

input[type="submit"] .icon--only,
input[type="reset"] .icon--only,
input[type="button"] .icon--only,
button .icon--only,
.btn .icon--only {
  margin-left: -1px;
  margin-right: 0;
}

.btn-group, .fc-button-group {
  font-size: 0;
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
}

.btn-group .btn, .btn-group button, .fc-button-group .btn, .fc-button-group button {
  margin-left: 0;
  margin-right: -1px;
  border-radius: 0;
  font-size: 14px;
  font-size: 1.4rem;
}

.btn-group .btn:first-child, .btn-group button:first-child, .fc-button-group .btn:first-child, .fc-button-group button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group .btn:last-child, .btn-group button:last-child, .fc-button-group .btn:last-child, .fc-button-group button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-group:first-child, .fc-button-group:first-child {
  margin-left: 0;
}

.table {
  width: 100%;
  background-color: transparent;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin-bottom: 2%;
  color: #444;
}

.table td {
  padding: 10px 6px;
}

.table th {
  text-align: left;
  padding: 2px 6px 8px;
}

.table th {
  font-weight: bold;
  border-bottom: 1px solid #c8c8c8;
  font-size: 10px;
  font-size: 1rem;
}

.table td {
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  height: 40px;
  font-size: 13px;
  font-size: 1.3rem;
}

.table.table--striped tbody tr:nth-child(odd) td {
  background: #fdfdfd;
  -webkit-print-color-adjust: exact;
}

.table.table--striped tfoot td {
  background: #fff;
  border-bottom: none;
  font-style: italic;
}

.table.table--vertical th {
  vertical-align: middle;
}

.table.is-clickable tbody tr:hover td {
  background: #fafafa;
}

.table.is-clickable td {
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  z-index: 7500;
  transition: opacity .3s 0, visibility .3s 0;
}

.modal--absolute {
  position: absolute;
}

.modal__container {
  transform: translateY(-40px);
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin: 12em auto 0;
  background: #fff;
  position: relative;
  z-index: 8000;
  width: 400px;
  min-width: 400px;
  max-width: 90%;
  transition: transform .3s;
}

.modal__header {
  padding: 2%;
  background: #eaeaea;
  position: relative;
  border-radius: 5px 5px 0 0;
}

.modal__header .js-close-modal {
  font-weight: bold;
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #888;
  top: 15px;
  width: 25px;
  height: 25px;
  text-align: center;
}

.modal__header .js-close-modal:hover {
  color: #666;
}

.modal__body {
  padding: 15px 10px;
  overflow-y: auto;
}

.modal__body h3:first-child {
  margin-top: -14px;
  margin-bottom: 20px;
}

.modal__body hr {
  margin: 20px 0;
}

.modal__body::-webkit-scrollbar {
  width: 4px;
}

.modal__body::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

.modal__body::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.modal__body::-webkit-scrollbar-track {
  background-color: #eee;
  border-left: 1px solid #CECECE;
}

.modal p {
  margin-bottom: 12px;
  line-height: 1.5;
}

.modal p:last-child {
  margin-bottom: 0;
}

.modal.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s 0, visibility 0 0;
}

.modal.is-visible .modal__container {
  transform: translateY(0px);
}

.modal__buttons {
  font-size: 0;
  text-align: justify;
}

.modal__buttons a {
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
  height: 60px;
  position: relative;
  line-height: 60px;
  text-transform: uppercase;
  color: #333 !important;
  background: #fff;
  transition: background-color .2s;
}

.modal__buttons a.is-loading {
  transition: color linear .2s;
  color: rgba(255, 255, 255, 0) !important;
  cursor: default;
}

.modal__buttons a:hover {
  background: #f2f2f2;
}

.modal__buttons li {
  display: inline-block;
  text-align: center;
  width: 50%;
}

.modal__buttons li:first-child a {
  border-radius: 0 0 0 5px;
}

.modal__buttons li:last-child a {
  border-radius: 0 0 5px 0;
}

.modal__buttons li .primary {
  background: #f52f33;
  color: #fff !important;
}

.modal__buttons li .primary:hover {
  background: #f6474b !important;
}

.modal__buttons li .secondary {
  background: #3278f5;
  color: #fff !important;
}

.modal__buttons li .secondary:hover {
  background: #4a88f6 !important;
}

.modal__buttons--single li {
  width: 100%;
}

.modal__buttons--single li a {
  border-radius: 0 0 5px 5px !important;
}

.modal__buttons--triple li {
  width: 33.3333%;
}

.modal-confirm .modal__container {
  width: 500px;
}

.modal-confirm__body {
  text-align: center;
}

.modal-confirm__body p {
  padding: 2em 1em;
  margin: 0;
  font-size: 15px;
  font-size: 1.5rem;
}

.modal-confirm__buttons li:first-child a {
  background: #ea6048;
  color: #fff;
}

.modal-confirm__buttons li:first-child a:hover {
  background: #ed745f;
}

[data-title]:before, [data-title]:after {
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease-out, visibility .25s ease-out;
}

.title-visible:hover:before, .title-visible:hover:after {
  opacity: 1;
  visibility: visible;
}

.title-visible:hover:before {
  content: attr(data-title);
  padding: 4px 8px;
  color: #fff;
  position: absolute;
  left: 49%;
  top: -30px;
  white-space: nowrap;
  z-index: 20;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.8);
  transform: translateX(-50%);
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: normal;
}

.title-visible:hover:after {
  width: 0;
  height: 0;
  left: 50%;
  top: -4px;
  position: absolute;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.dashboard .col {
  float: left;
  width: 30%;
  margin-right: 4.5%;
}

.dashboard .col--right {
  margin-right: 0;
}

.dashboard-widget {
  margin-bottom: 4em;
}

.dashboard-widget__title {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: .4em;
}

.dashboard-widget__title .count {
  color: #e1e1e5;
}

.dashboard-widget__title .btn-group {
  margin-top: 2px;
  vertical-align: top;
}

.dashboard-widget__content {
  border: 3px solid #EFEFF1;
  padding: 1em;
  border-radius: 5px;
  min-height: 307px;
  position: relative;
}

.dashboard-widget__actions {
  float: right;
}

.dashboard-widget .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-widget .list-entry {
  min-height: 50px;
  padding: 1em 0 1em;
  border-bottom: 1px solid #EFEFF1;
}

.dashboard-widget .list-entry:first-child {
  padding-top: 0;
}

.dashboard-widget .list-entry:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.dashboard-widget .list-entry__title {
  line-height: 1.7;
  margin-bottom: .25em;
}

.dashboard-widget .list-entry__description {
  font-size: 13px;
  font-size: 1.3rem;
}

.dashboard-widget .list-entry__description p {
  margin-bottom: 0;
}

.dashboard-widget.dashboard-widget--red a, .dashboard-widget.dashboard-widget--red h2 {
  color: #ea6048;
}

.dashboard-widget.dashboard-widget--orange a, .dashboard-widget.dashboard-widget--orange h2 {
  color: #faad28;
}

.dashboard-widget.dashboard-widget--purple a, .dashboard-widget.dashboard-widget--purple h2 {
  color: #d7649f;
}

.dashboard-widget.dashboard-widget--green a, .dashboard-widget.dashboard-widget--green h2 {
  color: #48b059;
}

.dashboard-widget .empty-state {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.dashboard-widget .empty-state img {
  margin-bottom: 1.25em;
}

.dashboard-widget .empty-state h5 {
  font-weight: 300;
  color: #666;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 1.25em;
}

.widget-rows {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
}

.widget-row {
  display: flex;
  flex: 1 1 auto;
  align-self: auto;
}

.widget-row--top {
  order: 1;
  height: 100px;
}

.widget-row--bottom {
  order: 2;
  height: 170px;
}

.widget-row .gauge {
  height: 170px;
  width: 100%;
}

.gauge-cols {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
  width: 100%;
}

.gauge-col {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  width: 50%;
  text-align: center;
}

.gauge-col h2 {
  color: #AAA !important;
  line-height: 1.6;
  margin-top: .25em;
  margin-bottom: 0;
}

.gauge-col p {
  color: #aaa;
}

.usage-meter {
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: 4px;
}

.usage-meter--right {
  float: right;
  width: 40%;
  margin-top: -.4em;
}

.usage-meter__label {
  font-size: 12px;
  font-size: 1.2rem;
  position: absolute;
  right: 0;
  color: #888;
}

.usage-meter__bar {
  position: absolute;
  height: 5px;
  background: #EFEFF1;
  bottom: 0;
  width: 100%;
}

.usage-meter__bar-inner {
  height: 100%;
  background: #ea6048;
}

.alert {
  padding: 15px;
  background: #F0F0F0;
  border-radius: 4px;
  color: #444;
  margin-bottom: 20px;
  max-width: 500px;
  line-height: 1.4;
}

.alert-danger {
  background: #ea6048;
  color: #fff;
}

.alert-warning {
  background: #faad28;
  color: #fff;
}

.alert-success {
  background: #48b059;
  color: #fff;
}

.toolbar {
  margin-bottom: 20px;
}

.overlay {
  position: fixed;
  left: 0;
  top: 52px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: visibility ease .3s, opacity ease .3s;
  z-index: 7900;
  will-change: opacity, visibility;
}

.overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

.overlay.is-visible .widgetbrowser__body {
  animation: fadeIn .2s 1;
  transform: translate(-50%, 0%);
}

.overlay.is-visible .filebrowser__body {
  animation: fadeIn .2s 1;
  transform: translate(-50%, 0%);
}

.dropdown-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: none;
  z-index: 1500;
}

.dropdown-overlay.is-visible {
  display: block;
}

.frame {
  border: 3px solid #EFEFF1;
  padding: 1em;
  border-radius: 5px;
  overflow: auto;
  min-height: 280px;
}

.frame + .frame {
  margin-top: 50px;
}

.frame.frame--height-auto {
  min-height: 0;
}

.frame h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #666;
}

.frame .form-control input {
  width: 100%;
}

.row {
  clear: both;
  overflow: auto;
}

.row + .row {
  margin-top: 35px;
}

.screen-list {
  clear: both;
  margin-top: 3.5em;
}

.screen-list .screen {
  float: left;
  width: 22%;
  margin-right: 3.5%;
  margin-bottom: 4em;
}

.screen-list .screen:nth-child(4n) {
  margin-right: 0;
}

.screen-list .screen__preview {
  border: 2px solid #EFEFF1;
  margin-bottom: .5em;
  position: relative;
  padding: 10px;
}

.screen-list .screen__label {
  width: calc(100% - 50px);
}

.screen-list .screen__actions {
  cursor: pointer;
  position: relative;
  float: right;
  left: 3px;
  bottom: 4px;
  padding: .15em .025em;
  color: #666;
  border-radius: 3px;
  font-size: 1.3em;
  background: #fff;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;
  z-index: 3000;
}

.screen-list .screen__actions.is-open .actions {
  display: block;
}

.screen-list .screen__actions:hover, .screen-list .screen__actions.is-open {
  border-color: #EFEFF1;
}

.screen-list .screen__title {
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4;
  height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: .3em;
}

.screen-list .screen__description {
  color: #aaa;
  line-height: 1.4;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.screen-list .screen__meta {
  margin-top: .3em;
  border-top: 2px solid #EFEFF1;
  padding-top: 1em;
}

.screen-list .screen-unreachable span {
  background: #ea6048;
  color: #fff;
  padding: 1px 3px;
  border-radius: 3px;
}

.screen-list .screen-meta {
  width: 100%;
  color: #888;
  font-size: 11px;
  font-size: 1.1rem;
}

.screen-list .screen-meta td {
  text-align: right;
  vertical-align: middle;
  height: 18px;
}

.screen-list .screen-meta th {
  font-weight: bold;
  text-align: left;
}

.screen-list .empty {
  background: #fafafa;
  text-align: center;
  padding: 3em;
}

.screen-list .empty__heading {
  font-size: 26px;
  font-size: 2.6rem;
  margin-bottom: 1em;
  margin-top: .5em;
}

.screen-list .empty__text {
  font-size: 17px;
  font-size: 1.7rem;
  color: #666;
  margin-bottom: 2em;
}

.screen-list .empty__action {
  margin-bottom: 0;
}

.screen-list .actions {
  position: absolute;
  display: none;
  width: 200px;
  border: 2px solid #EFEFF1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style: none;
  z-index: 2000;
  background: #fff;
  left: 10px;
  top: 10px;
  border-radius: 3px;
}

.screen-list .actions__action {
  display: block;
  font-size: .7em;
  padding: .65em;
  border-bottom: 1px solid #f0f0f0;
}

.screen-list .actions__action a {
  display: block;
}

.screen-list .actions__action a:not(:hover) {
  color: #aaa;
}

.screen-list .actions__action:last-child {
  border-bottom: 0;
}

.screen-list .actions__action:hover {
  background: #fafafa;
}

.screen-list .actions__action:hover a {
  color: #ea6048;
}

.screen-list .actions__action .icon {
  margin-right: 5px;
}

.type-selector__type {
  border: 2px solid #e0e0e0;
  padding: 10px;
  width: 180px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.type-selector__type--active {
  border-color: #48b059;
  color: #48b059;
}

.user-avatar {
  border-radius: 50%;
  color: #2db8ba;
  border: 2px solid #2db8ba;
  background: #fff;
  display: inline-block;
  position: absolute;
  right: 11px;
  padding: 9px 6px;
  top: 7px;
  width: 37px;
  height: 36px;
  text-align: center;
}

.nav-user {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 170px;
}

.nav-user .nav-entry a {
  padding: 19px 62px 18px 20px;
  background: #f0f0f0;
  color: #333 !important;
}

.nav-user .nav-entry a:hover {
  color: #333 !important;
}

.nav-user .nav-entry:hover .nav-sub {
  display: block;
}

.nav-user .nav-sub {
  position: absolute;
  top: 47px;
  left: 0;
  width: 100%;
  padding-top: 8px;
  display: none;
}

.nav-user .nav-sub li {
  display: block;
  padding: 0;
}

.nav-user .nav-sub li a {
  color: #333;
  display: block;
  background: #fafafa;
}

.nav-user .nav-sub li a:hover {
  background: #fdfdfd;
}

/* Large devices (large desktops, 1200px and down) */
/* Medium devices (desktops, 1158px and down) */
@media (max-width: 1158px) {
  .dashboard .col {
    width: 45%;
    margin-right: 5%;
  }
}

/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
  .dashboard .col {
    width: 100%;
    margin-right: 0;
  }
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
  color: #2db8ba;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today.xdsoft_current {
  color: #fff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #2db8ba;
  box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
  font-weight: normal;
}

/* Remove that awful yellow color and border from today in Schedule */
.fc-today {
  opacity: 0;
  border: none;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: .95;
  left: 2% !important;
  right: 2% !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: .25 !important;
}

.fc-title {
  font-weight: bold;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: .65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

.fc-toolbar {
  margin-bottom: .5em;
}

.fc-toolbar h2 {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.fc th {
  padding: 5px 0;
}

.fc td, .fc th, .ui-widget-header, .ui-widget-content {
  border-color: #ccc;
  height: 25px;
  min-height: 25px;
}

.fc-button-group {
  margin-left: 10px !important;
}

a.fc-event:hover {
  color: #fff !important;
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 0;
}

.fc-divider {
  min-height: 3px;
}

.workbench-toolbar .sp-replacer {
  border-radius: 4px;
  background: #fafafa;
  appearance: button;
  cursor: pointer;
  display: inline-block;
  min-width: 40px;
  width: 100%;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  transition: background .2s ease-out;
  border: 2px solid #e0e0e0;
  padding: 0;
  margin: 0;
}

.workbench-toolbar .sp-replacer:hover {
  background: #eee;
}

.workbench-toolbar .sp-replacer.sp-disabled {
  cursor: default;
  background: #f0f0f0;
  border-color: #eee !important;
  color: #888 !important;
}

.workbench-toolbar .sp-replacer .sp-preview {
  width: 100%;
  float: none;
  border: none;
  height: 27px;
}

.workbench-toolbar .sp-replacer .sp-dd {
  display: none;
}

body .sp-palette-container {
  max-width: 41px;
  border-right: solid 1px #eee;
}

body .sp-container {
  background-color: #FEFEFE;
  padding: 0.2em;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #DDD;
}

body .sp-color, body .sp-hue, body .sp-clear {
  border: solid 1px #CCC;
}

body .sp-hide {
  display: none !important;
}

.color-green .sweet-alert button {
  box-shadow: none !important;
}

.color-green .sweet-alert button.confirm {
  background: #48b059 !important;
  color: #fff !important;
}

.color-orange .sweet-alert button {
  box-shadow: none !important;
}

.color-orange .sweet-alert button.confirm {
  background: #faad28 !important;
  color: #fff !important;
}

.color-red .sweet-alert button {
  box-shadow: none !important;
}

.color-red .sweet-alert button.confirm {
  background: #ea6048 !important;
  color: #fff !important;
}

.color-blue .sweet-alert button {
  box-shadow: none !important;
}

.color-blue .sweet-alert button.confirm {
  background: #2db8ba !important;
  color: #fff !important;
}

.color-purple .sweet-alert button {
  box-shadow: none !important;
}

.color-purple .sweet-alert button.confirm {
  background: #d7649f !important;
  color: #fff !important;
}

.color-grey .sweet-alert button {
  box-shadow: none !important;
}

.color-grey .sweet-alert button.confirm {
  background: #666 !important;
  color: #fff !important;
}

.sweet-alert h2 {
  font-size: 25px !important;
}

.sweet-alert p {
  font-size: 18px !important;
  line-height: 1.7 !important;
}

.sweet-alert button.cancel {
  background: #fafafa;
  border: 1px solid #e0e0e0;
}

.item-editor {
    background: #fafafa;
    float: left;
    width: 800px;
    height: 100vh;
    top: 0;
    padding-top: 160px;
    box-shadow: inset 1px 0 9px rgba(0, 0, 0, .1);
    position: fixed;
    left: 50%;
    overflow-y: auto;
    @extend %betterScrollbars;

    &__content {
        overflow: auto;
    }
    &__loader {
        position: absolute;
        display: flex;
        justify-content: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5000;
        background: rgba(255, 255, 255, .6);
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s ease-out;

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }
    }
    &__blank {
        border: 1px solid #ddd;
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        padding: 1em;
        text-align: center;
        border-radius: 4px;
        background: #fff;
        color: #666;
        top: 50%;
        position: absolute;
        width: 440px;
        left: 50%;
        transform: translateX(-50%);
        &--static {
            transform: none;
            width: 100%;
            margin: 2em 0;
            position: relative;
            left: 0;
        }
        i {
            position: absolute;
            left: 10px;
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"],
    input[type="date"],
    input[type="datetime"],
    input[type="time"],
    input[type="range"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="file"],
    textarea,
    select {
        width: 100%;
    }
}

.item-editor-dropzone.dropzone {
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    padding: 1em;
    text-align: center;
    border-radius: 4px;
    background: #fff;
    color: #666;
    .dz-message {
        margin-top: 3.6em;
        color: #666;
    }
}

.dropdown {
    border-radius: 4px;
    background: #fafafa;
    border: 1px solid #e0e0e0;
    color: #444;
    @include font-size(1.4);
    line-height: 18px;
    appearance: button;
    cursor: pointer;
    display: inline-block;
    min-width: 180px;
    vertical-align: middle;
    user-select: none;
    position: relative;
    white-space: nowrap;
    box-sizing: border-box;
    z-index: 1000;
    a {
        color: #444;
        display: block;
        padding: 8px 12px;
    }
    li {
        padding: 0;
        &.dropdown__label {
            @include font-size(1.1);
            background: #f5f5f5;
            cursor: default;
            padding: 3px 12px;
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;
            &:hover {
                background: #f5f5f5 !important;
                color: #444 !important;
            }
        }
        &.dropdown__active {
            border-radius: 4px;
            padding: 8px 12px;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                border-color: #2b2b2b transparent transparent transparent;
                position: absolute;
                right: 12px;
                top: 15px;
            }
        }
        ul li:hover {
            color: #fff;
            a {
                color: #fff;
            }
        }
    }
    ul {
        position: absolute;
        background: inherit;
        margin: 0;
        left: -1px;
        width: 100%;
        width: calc(100% + 2px);
        z-index: 1000;
        top: 34px;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 0 4px 4px 4px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s ease, visibility .2s ease;
        box-shadow: 0 0 10px rgba(0,0,0,.11);
    }
    &:hover {
        background: #fff;
    }
    &.is-open {
        border-radius: 4px 4px 0 0;
        ul {
            opacity: 1;
            visibility: visible;
        }
    }
}

.sort-selector {
    min-width: 50px;
    a {
        padding: 10px 12px;
    }
    li {
        &.dropdown__active {
            padding: 8px 6px;
        }
        &.dropdown__active:after {
            top: 14px;
            right: 10px;
        }
    }
    ul {
        min-width: 180px;
        top: 30px;
    }
}

.item-selector {
    margin-top: 6px;
}
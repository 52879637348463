.dashboard {
    .col {
        float: left;
        width: 30%;
        margin-right: 4.5%;
    }

    .col--right {
        margin-right: 0;
    }
}

.dashboard-widget {
    margin-bottom: 4em;
    &__title {
        @include font-size(2);
        font-weight: 500;
        line-height: 1.7;
        margin-bottom: .4em;
        .count {
            color: darken($light-grey, 5);
        }
        .btn-group {
            margin-top: 2px;
            vertical-align: top;
        }
    }
    &__content {
        border: 3px solid $light-grey;
        padding: 1em;
        border-radius: 5px;
        min-height: 307px;
        position: relative;
    }
    &__actions {
        float: right;
    }

    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .list-entry {
        min-height: 50px;
        padding: 1em 0 1em;
        border-bottom: 1px solid $light-grey;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
        &__title {
            line-height: 1.7;
            margin-bottom: .25em;
        }
        &__description {
            @include font-size(1.3);
            p {
                margin-bottom: 0;
            }
        }
    }
    &.dashboard-widget--red {
        a, h2 {
            color: $red;
        }
    }
    &.dashboard-widget--orange {
        a, h2 {
            color: $orange;
        }
    }
    &.dashboard-widget--purple {
        a, h2 {
            color: $purple;
        }
    }
    &.dashboard-widget--green {
        a, h2 {
            color: $green;
        }
    }


    .empty-state {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        img {
            margin-bottom: 1.25em;
        }

        h5 {
            font-weight: 300;
            color: $grey;
            @include font-size(1.8);
            margin-bottom: 1.25em;
        }
    }
}

.widget-rows {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: stretch;
}
.widget-row {
    display: flex;
    flex: 1 1 auto;
    align-self: auto;
    &--top {
        order: 1;
        height: 100px;
    }
    &--bottom {
        order: 2;
        height: 170px;
    }
    .gauge {
        height: 170px;
        width: 100%;
    }
}

.gauge-cols {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: stretch;
    width: 100%;
}
.gauge-col {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    width: 50%;
    text-align: center;
    h2 {
        color: #AAA !important;
        line-height: 1.6;
        margin-top: .25em;
        margin-bottom: 0;
    }
    p {
        color: #aaa;
    }
}

.usage-meter {
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 4px;
    &--right {
        float: right;
        width: 40%;
        margin-top: -.4em;
    }
    &__label {
        @include font-size(1.2);
        position: absolute;
        right: 0;
        color: #888;
    }
    &__bar {
        position: absolute;
        height: 5px;
        background: $light-grey;
        bottom: 0;
        width: 100%;
    }
    &__bar-inner {
        height: 100%;
        background: $red;
    }
}
.nav-header__nav {
    @include font-size(0);
    li {
        display: inline-block;
        &:hover a,
        &.active a {
            color: #fff;
        }
    }
    a {
        @include font-size(1.4);
        color: #333;
        font-weight: 500;
        display: inline-block;
        padding: 20px 20px 17px;
        transition: .2s ease background-color, .2s ease color;
    }
}

.nav-entry {
    &__screens {
        &.active a, &:hover a {
            background: $red;
        }
    }
    &__slideshows {
        &.active a, &:hover a {
            background: $orange;
        }
    }
    &__timeline {
        &.active a, &:hover a {
            background: $blue;
        }
    }
    &__datasets {
        &.active a, &:hover a {
            background: $green;
        }
    }
}

.nav-sidebar {
    border-radius: 4px;
    ul {
        padding: 12px 0;
    }
    li {
        @include font-size(1.4);
    }
    a {
        color: #484848;
        padding: 10px 12px 10px 36px;
        font-weight: 500;
        display: block;
        border-radius: 4px;
        &:hover {
            background: #eee;
            color: inherit;
        }
    }
    .icon {
        display: inline-block;
        width: 27px;
        margin-left: -31px;
        text-align: center;
    }
    .badge {
        float: right;
        font-weight: normal;
    }
    .active a {
        color: #fff;
    }
}
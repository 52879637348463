.alert {
    padding: 15px;
    background: #F0F0F0;
    border-radius: 4px;
    color: #444;
    margin-bottom: 20px;
    max-width: 500px;
    line-height: 1.4;
    &-danger {
        background: $red;
        color: #fff;
    }
    &-warning {
        background: $orange;
        color: #fff;
    }
    &-success {
        background: $green;
        color: #fff;
    }
}

.toolbar {
    margin-bottom: 20px;
}

.overlay {
    position: fixed;
    left: 0;
    top: 52px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    visibility: hidden;
    transition: visibility ease .3s, opacity ease .3s;
    z-index: 7900;
    will-change: opacity, visibility;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        .widgetbrowser__body {
            animation: fadeIn .2s 1;
            transform: translate(-50%, 0%)
        }
        .filebrowser__body {
            animation: fadeIn .2s 1;
            transform: translate(-50%, 0%)
        }
    }
}

.dropdown-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 1500;
    &.is-visible {
        display: block;
    }
}

.frame {
    border: 3px solid $light-grey;
    padding: 1em;
    border-radius: 5px;
    overflow: auto;
    min-height: 280px;
    +.frame {
        margin-top: 50px;
    }

    &.frame--height-auto {
        min-height: 0;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 20px;
        color: #666;
    }
    .form-control input {
        width: 100%;
    }
}

.row {
    clear: both;
    overflow: auto;
    + .row {
        margin-top: 35px;
    }
}
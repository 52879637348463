main {
    display: block; // Fuck you IE11
}

body, html {
    height: 100%;
    min-height: 100%;
    color: #333;

    @extend %betterScrollbars;
}

.content {
    @include column(7);
    @include push(4);
    margin-top: 40px;
    &.no-sidebar {
        @include column(10);
        @include push(1);
    }
}
.fullbleed {
    background: #EEE;
    .content {
        margin-top: 0;
        height: 100%;
        @include column(12);
        @include push(0);
    }
    .header {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
}

.fixed-header {
    padding-top: 55px;
    .header {
        position: fixed;
        width: 100%;
        top: 0;
    }
}

.sidebar {
    @include column(2);
    @include push(1);
    margin-top: 40px;
}

.header {
    @include font-size(0);
    background: #F1F1F1;
    height: 55px;
    border-bottom: 4px solid;
    position: relative;
    z-index: 8000;
}

.nav-header {
    @include font-size(1.4);
    @include column(6);
    @include push(1);
}

.header-actions {
    @include font-size(1.4);
    @include column(5);
    @include push(7);
    text-align: right;
    height: 50px;
    line-height: 50px;
    padding-right: 20px;
    & > * {
        line-height: 50px;
        margin-bottom: 0;
    }
}

.login {
    @include column(4);
    @include push(4);
    border: 1px solid #aaa;
    padding: $gutter_width;
}

.wrapper {
    min-height: 100%;
    margin: 0 auto -220px;
    &:after {
        content: "";
        display: block;
    }
}
.logo {
    @include column(1);
    padding: 8px;
}
//.footer,
//.wrapper:after {
//    height: 220px;
//}

.content-wrapper {
    clear: both;
    position: absolute;
    width: 100%;
    height: 100%;
    height: calc(100% - 55px);

    @extend %betterScrollbars;
}

.footer {
    clear: both;
    background: #f1f1f1;
    padding-top: 40px;
    overflow: auto;
    @include container();
    @include font-size(1.6);
    font-weight: 500;
    &__help {
        @include push(1);
        @include column(4);
        padding-left: 100px;
        background: url('../images/icon-support.png') no-repeat 0 5px;
    }
    &__about {
        @include push(5);
        @include column(3);
    }
    a:link,
    a:visited,
    a:hover,
    a:active {
        color: #333 !important;
    }
}

.timeline-editor {
    margin-top: 40px;
}

.col-50 {
    float: left;
    &--right {
        width: 49%;
    }
    &--left {
        width: 49%;
        margin-right: 2%;
    }
}
/* Large devices (large desktops, 1200px and down) */
@media (max-width: 1200px) {

}

/* Medium devices (desktops, 1158px and down) */
@media (max-width: 1158px) {
    .dashboard {
        .col {
            width: 45%;
            margin-right: 5%;
        }
    }
}


/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
    .dashboard {
        .col {
            width: 100%;
            margin-right: 0;
        }
    }
}

.workbench-toolbar {
    .sp-replacer {
        border-radius: 4px;
        background: #fafafa;
        appearance: button;
        cursor: pointer;
        display: inline-block;
        min-width: 40px;
        width: 100%;
        vertical-align: middle;
        user-select: none;
        white-space: nowrap;
        transition: background .2s ease-out;
        border: 2px solid #e0e0e0;
        padding: 0;
        margin: 0;
        &:hover {
            background: #eee;
        }
        &.sp-disabled {
            cursor: default;
            background: #f0f0f0;
            border-color: #eee !important;
            color: #888 !important;
        }
        .sp-preview {
            width: 100%;
            float: none;
            border: none;
            height: 27px;
        }
        .sp-dd {
            display: none;
        }
    }
}

body {
    .sp-palette-container {
        max-width: 41px;
        border-right: solid 1px #eee;
    }
    .sp-container {
        background-color: #FEFEFE;
        padding: 0.2em;
        border-radius: 4px;
        box-shadow: 0 0 15px rgba(0, 0, 0, .1);
        border: 1px solid #DDD;
    }
    .sp-color, .sp-hue, .sp-clear {
        border: solid 1px #CCC;
    }
    .sp-hide {
        display: none !important;
    }
}
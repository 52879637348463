.item-list {
    margin-bottom: 20px;
    &--split {
        width: 50%;
        float: left;
        .item-list__pagination {
            margin-right: 10px;
        }
    }
    &__item,
    &__header {
        border-bottom: 1px solid #E0E0E0;
        padding: 18px 0;
        position: relative;
        > div {
            display: inline-block;
            vertical-align: top;
        }
    }
    &__item {
        &--no-description {
            .item-list__title {
                margin-bottom: 0;
            }
            .item-list__item-actions {
                top: 13px;
            }
        }
        &.is-selected {
            background: #fafafa;
        }
    }
    &__header {
        border-bottom: 1px solid #ddd;
    }
    &__pagination {
        float: right;
    }
    &__actions {
        margin-top: -8px;
    }
    &__checkbox {
        width: 50px;
    }
    &__image {
        width: 120px;
    }
    &__meta {
        width: 94%;
        width: calc(100% - 55px);
    }
    &__item-actions {
        position: absolute;
        right: 12px;
        top: 24px;
    }
    &__title {
        @include font-size(2);
        line-height: 1;
    }
    &__description {
        font-weight: normal;
    }
    &__secondary-action {
        float: right;
    }
    p {
        font-weight: normal;
        margin-bottom: 0;
        color: #666;
    }
}
.item-list__item--compact {
    .item-list__title {
        margin-bottom: 0;
        line-height: 1.3;
    }
    .item-list__checkbox {
        position: relative;
        top: 6px;
    }

}

.flash {
    position: fixed;
    top: 10px;
    left: 50%;
    min-width: 22%;
    max-width: 1000px;
    white-space: nowrap;
    text-align: center;
    padding: 8px 10px;
    background: #fefefe;
    visibility: visible;
    opacity: 1;
    z-index: 20000;
    border-radius: 2px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .05);
    transform: translateX(-50%);
    animation: flashFadeInDown .5s;
    &.is-hidden {
        animation: flashFadeOutUp 1s;
        top: -150%;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
    &--error, &--danger {
        background: $red;
        color: #fff;
    }
    &--success {
        background: $green;
        color: #fff;
    }
    &--warning {
        background: $orange;
        color: darken($orange, 50);
    }
    &--shell {
        opacity: 0;
        top: -150%;
        display: none;
    }
}
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.btn {
    border-radius: 4px;
    background: #fafafa;
    color: #444 !important;
    border: 1px solid #e0e0e0;
    @include font-size(1.4);
    line-height: 18px;
    font-weight: normal;
    font-family: 'Open Sans', Arial, sans-serif;
    padding: 8px 12px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    position: relative;
    white-space: nowrap;
    transition: background .2s ease-out;

    &:hover,
    &.btn--pressed {
        background: #eee;
    }
    &:active {
        transform: scale(0.98);
    }
    &[disabled],
    &.disabled {
        cursor: default;
        background: #f0f0f0;
        border-color: #eee;
        color: #888 !important;
    }
    @each $color in $colorList {
        &.btn-#{nth($color,1)} {
            background: nth($color, 2);
            color: #fff !important;
            border-color: nth($color, 2);
            &:hover {
                background: lighten(nth($color, 2), 5);
            }
            &[disabled],
            &.disabled {
                background: lighten(nth($color, 2), 25) !important;
                border-color: lighten(nth($color, 2), 25) !important;
                color: rgba(255, 255, 255, .7) !important;
            }
        }
    }
    &.btn--borderless {
        border: none;
        background: none;
        &:hover {
            background: #f0f0f0;
        }
        &[disabled],
        &.disabled {
            color: #999 !important;
        }
    }
    &.btn--light {
        border-color: darken($light-grey, 5);
        background: transparent;
        color: lighten($grey, 20) !important;
        &:hover {
            color: $grey !important;
            border-color: darken($light-grey, 10);
        }
    }
    &.btn--small {
        padding: 4px 10px;
    }
    &.btn-delete {
        @extend %hidden;
        &.is-visible {
            @extend %visible;
        }
    }
    .icon {
        margin-right: 6px;
        margin-left: -4px;
    }
    .icon--only {
        margin-left: -1px;
        margin-right: 0;
    }
}

.btn-group, .fc-button-group {
    font-size: 0;
    display: inline-block;
    margin-left: 12px;
    vertical-align: middle;
    .btn, button {
        margin-left: 0;
        margin-right: -1px;
        border-radius: 0;
        @include font-size(1.4);
    }
    .btn:first-child, button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .btn:last-child, button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    &:first-child {
        margin-left: 0;
    }
}
/**
 * Profound Grid
 *
 * Built in Sass (SCSS) this responsive/adaptive grid supports both fixed and fluid layouts,
 * relative and fixed gutters, as well as your choice of semantic markup or generic '.grid-x' classes.
 *
 * The biggest difference to other grid systems is the use of negative margins for column
 * placements, avoiding the subpixel rounding issues that usually break/uglify fluid layouts
 * in some browsers.
 *
 * Nested columns are supported too, without having to reset the grid on each level.
 *
 * Credits/Inspiration:
 * -------------------
 * Semantic Grid: http://www.semantic.gs
 * Susy: http://susy.oddbird.net
 * Negative Grid: http://chrisplaneta.com/freebies/negativegrid-fluid-css-grid-by-chris-planeta/
 *
 * @author Profound Creative Studio
 * @url http://www.profoundgrid.com
 * @url http://www.weareprofound.com
 */

////////////////////////////////////////////////////////////////////////////////
// Defaults (feel free to override)
////////////////////////////////////////////////////////////////////////////////

$total_columns: 	12;			// 				//
$total_width:		100%; 		// px|100% 		// px = fixed, 100% = fluid
$gutter_width:		1%;			// px|%			// px = fixed, % = fluid
$container_margin:	auto;			// auto|px|%	//

$class_container:	'row';		//				//
$class_column:		'col';		//				//
$class_push:		'push';		//				//


////////////////////////////////////////////////////////////////////////////////
// GRID MATH
////////////////////////////////////////////////////////////////////////////////

@function element_width($column_index){
    @if ($total_width == 100%){
        $not_rounded_value: (((100% + $gutter_width)/$total_columns)*$column_index)-$gutter_width;
        $not_rounded_value: $not_rounded_value * 100;
        $rounded_value: round($not_rounded_value)/100;
        @return $rounded_value;
    }@else{
        @return ((($total_width + $gutter_width)/$total_columns)*$column_index)-$gutter_width;
    }
}

@function column_width(){
    @if ($total_width == 100%){
        $not_rounded_value: (((100% + $gutter_width)/$total_columns))-$gutter_width;
        $not_rounded_value: $not_rounded_value * 100;
        $rounded_value: round($not_rounded_value)/100;
        @return $rounded_value * 1%;
    }@else{
        @return ($total_width - ($gutter_width*($total_columns - 1)))/$total_columns;
    }
}

@function container_width(){
    @if ($total_width == 100%){
        @if ($container_margin == auto){
            @return 100%;
        }
        @return 100% - 2*$container_margin;
    } @else {
        @return $total_width;
    }
}
////////////////////////////////////////////////////////////////////////////////
// BASIC MIXINS
////////////////////////////////////////////////////////////////////////////////

/**
 * Clearfix
 */
@mixin clearfix(){
    overflow: hidden;
    *zoom: 1;
}

/**
 * Legacy Clearfix
 */
@mixin legacy-pie-clearfix(){
    *zoom: 1;

    :after {
        content: "\0020";
        display: block;
        height: 0;
        clear: both;
        overflow: hidden;
        visibility: hidden;
    }
}

/**
 * Establish the grid-containing element.
 */
@mixin container(){
    width: container_width();
    margin: 0 $container_margin;
}

/**
 * Align an element to the grid.
 */
@mixin column($_columns_width, $_padding_columns:null){
    float: left;
    margin-right: -100%;
    $_width_perc: element_width($_columns_width);
    width: $_width_perc;

    @if($_padding_columns != null){
        @include push($_padding_columns);
    }
}

/**
 * Get Width Only
 */
@mixin column_width($_columns_width){
    $_width_perc: element_width($_columns_width);
    width: $_width_perc;
}

/**
 * Apply to any column to make it the last one of the current row.
 */
@mixin column_omega(){

}


////////////////////////////////////////////////////////////////////////////////
// MARGIN MIXINS
////////////////////////////////////////////////////////////////////////////////

@mixin pre(){}
@mixin post(){}
@mixin squish(){}

@mixin push($_column_index){
    $_width_perc: 0;
    @if ($total_width == 100%){
        $_width_perc: ($gutter_width + element_width($_column_index));
    }@else{
        //$_width_perc: (column_width() + $gutter_width)*$_column_index;
        $_width_perc: ($gutter_width + element_width($_column_index));
    }

    margin-left: $_width_perc;
}

@mixin pull(){}


////////////////////////////////////////////////////////////////////////////////
// HELPER CLASSES
////////////////////////////////////////////////////////////////////////////////

@mixin generate_helper_classes(){

    /* Helper Class: Container */
    .#{$class_container}{
        @include container();
    }

    /* Helper Class: Columns */
    @for $i from 1 through $total_columns{
        .#{$class_column}#{$i} {
            @include column($i)
        }
    }

    /* Helper Class: Horizontal Position */
    @for $i from 0 through ($total_columns - 1){
        .#{$class_push}#{$i} {
            @include push($i);
        }
    }

}

@mixin generate_grid_positions($_column_selector, $_element_width) {

    $_cols_per_row: floor($total_columns / $_element_width);

    // create rule for each element
    @for $i from 1 through $_cols_per_row {

        #{$_column_selector}:nth-child(#{$_cols_per_row}n+#{$i}) {
            @include push((($i - 1)*$_element_width));
            margin-bottom: $gutter_width;

            @if ($i == 1) {
                clear: both;
                @include clearfix();
            } @else {
                clear: none;
            }
        }
    }
}
@mixin generate_grid_positions_legacy($_column_selector, $_element_width, $_elements_in_grid:$total_columns){

    $_cols_per_row: floor($total_columns / $_element_width);
    $_current_col:1;

    // create rule for each element
    @for $i from 1 through $_elements_in_grid{

        $selector: #{$_column_selector};
        @for $ii from 2 through $i{
            $selector: (#{$selector}) + (#{$_column_selector});
        }


        #{$selector}{
            @include push((($_current_col - 1)*$_element_width));
            margin-bottom: $gutter_width;

            @if ($_current_col == 1) {
                clear: both;
                @include legacy-pie-clearfix();
            }@else{
                clear: none;
            }

            $_current_col: $_current_col + 1;
            @if ($_current_col > $_cols_per_row){
                $_current_col:1;
            }
        }
    }
}
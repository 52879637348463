
.pagination {
    float: right;
    li {
        display: inline-block;
        margin-right: 2px;
        &.active span {
            color: #fff;
            font-weight: bold;
        }
    }
    a, span {
        @include font-size(1.4);
        display: block;
        padding: 5px 8px;
        line-height: 1;
        border: 2px solid #f0f0f0;
        border-radius: 2px;
        transition: .3s ease border-color;
        &:hover {
            border-color: #e0e0e0;
        }
    }
    .disabled {
        display: none;
    }
}
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin bg-gradient($from, $to) {
    background: $from;
    background: -moz-linear-gradient(top, $from 0%, $to 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
    background: -webkit-linear-gradient(top, $from 0%,$to 100%);
    background: -o-linear-gradient(top, $from 0%,$to 100%);
    background: -ms-linear-gradient(top, $from 0%,$to 100%);
    background: linear-gradient(to bottom, $from 0%,$to 100%);
}

@mixin bg-loading() {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 1) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 1) 75%,
                    transparent 75%,
                    transparent
    );
    background-image: -moz-linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 1) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 1) 75%,
                    transparent 75%,
                    transparent
    );
    background-image: -ms-linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 1) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 1) 75%,
                    transparent 75%,
                    transparent
    );
    background-image: linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 1) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 1) 75%,
                    transparent 75%,
                    transparent
    );
    background-size: 50px 50px;
    @include animation('loading-background 2s linear infinite');
    overflow: hidden;
}

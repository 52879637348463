/* Remove that awful yellow color and border from today in Schedule */
.fc-today {
    opacity: 0;
    border: none;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
    border-radius: 4px;
    border: none;
    padding: 5px;
    opacity: .95;
    left: 2% !important;
    right: 2% !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
    z-index: 1 !important;
    background: inherit !important;
    opacity: .25 !important;
}

.fc-title {
    font-weight: bold;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
    font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
    opacity: .65 !important;
    margin-left: 12px !important;
    padding: 5px ! important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
    opacity: .65 !important;
    margin-left: 12px !important;
    padding: 5px ! important;
}

.fc-toolbar {
    margin-bottom: .5em;
    h2 {
        @include font-size(1.5);
        line-height: 2;
    }
}

.fc th {
    padding: 5px 0;
}

.fc td, .fc th, .ui-widget-header, .ui-widget-content {
    border-color: #ccc;
    height: 25px;
    min-height: 25px;
}

.fc-scroller {
    @extend %betterScrollbars;
}

.fc-button-group {
    margin-left: 10px !important;
}

a.fc-event:hover {
    color: #fff !important;
}

.fc-agenda-view .fc-day-grid .fc-row {
    min-height: 0;
}

.fc-divider {
    min-height: 3px;
}
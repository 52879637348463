.type-selector {
    &__type {
        border: 2px solid #e0e0e0;
        padding: 10px;
        width: 180px;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        &--active {
            border-color: $green;
            color: $green;
        }
    }
}
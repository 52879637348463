@include keyframes(spinner) {
    to {
        transform: rotate(360deg);
    }
}

@include keyframes(flashFadeInDown) {
    0% {
        opacity: 0;
        visibility: hidden;
        top: -50px;
    }
    100% {
        opacity: 1;
        visibility: visible;
        top: 10px;
    }
}

@include keyframes(flashFadeOutUp) {
    0% {
        opacity: 1;
        visibility: visible;
        top: 10px;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        top: -50px;
    }
}
@include keyframes(assetIntro) {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@include keyframes(fadeIn) {
    0% {
        transform: translate(-50%, 0%);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, 0%);
        opacity: 1;
    }
}